<template>
  <div class="custom-file-input">
    <input
      type="file"
      ref="file"
      @change="getFile"
      :accept="accept"
      :multiple="multiple"
    />
    <div class="login-form-control">
      <span class="file-input-placeholder">Upload</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    accept: {
      type: String,
      default: "image/png, image/jpg, image/jpeg",
    },
    multiple: {
      type: String,
      default: false,
    },
  },
  methods: {
    getFile() {
      if (this.multiple == true) {
        this.$emit("file", this.$refs.file.files);
      } else {
        this.$emit("file", this.$refs.file.files[0]);
      }
    },
  },
};
</script>
<style scoped>
.custom-file-input {
  position: relative;
}
.file-input-placeholder {
  color: #cacaca;
  line-height: 62px;
}
input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
</style>
