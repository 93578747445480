<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>

    <h5>Contact us</h5>
    <div class="p-sm-5 p-3 custom-card mt-3">
      <form @submit.prevent="submit">
        <div class="form-group mb-3">
          <label class="login-form-label">Name <span class="text-danger">*</span></label>
          <input type="text" class="login-form-control form-control" placeholder="Naveed" v-model="contactUs.name"
            required />
        </div>
        <div class="form-group mb-3">
          <label class="login-form-label">Email <span class="text-danger">*</span></label>
          <input type="email" class="login-form-control form-control" placeholder="info@gmail.com"
            v-model="contactUs.email" required />
        </div>
        <div class="form-group">
          <label class="login-form-label">Message <span class="text-danger">*</span></label>
          <textarea class="login-form-control-textarea form-control" placeholder="Write a message"
            v-model="contactUs.message" required></textarea>
        </div>
        <div class="row mt-4">
          <div class="col-md-3">
            <button type="submit" class="basic-info-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contactUs: {
        name: null,
        email: null,
        message: null,
      },
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.contactUs.user_id = localStorage.getItem("upId");
      const data = this.contactUs;
      this.$store
        .dispatch("contactUs/store", { data })
        .then((response) => {
          this.loading = false;
          this.$toast.show("We have received your application.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          Object.keys(this.contactUs).forEach(
            (k) => (this.contactUs[k] = null)
          );
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
