<template>
  <loading
    v-if="loading"
    :active="loading"
    :is-full-page="true"
    loader="bars"
  ></loading>
  <router-view />
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.loading = !this.loading;
      }, 1000);
      this.loading = !this.loading;
    },
  },
  mounted() {
    this.loading = false;
    this.fetchTranslation();
  },
  methods: {
    async fetchTranslation() {
      await this.$store
        .dispatch("translation/getAll")
        .then((response) => {
          const data = response.data;
          this.$store.dispatch("setTranslation", { data });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
