<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div>
      <div class="d-flex align-items-center home-emp-dash-create-job">
        <div class="ms-sm-5 ms-2">
          <div class="dash-create-job-pic text-center position-relative">
            <img v-if="profilePic" :src="profilePic" alt="" />
            <img
              v-else
              src="@/assets/images/Svg/dash-svg/dash-profile-pic.svg"
              alt=""
            />
          </div>
        </div>
        <button
          class="btn w-100"
          data-bs-toggle="modal"
          data-bs-target="#createJobModal"
        >
          <input
            type="text"
            class="search-create-job w-100"
            placeholder="Create a Job"
          />
        </button>
      </div>
    </div>

    <h5 class="home-emp-dash-recommend-heading fw-normal ms-2 mb-3 mt-3">
      Jobs
    </h5>

    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="active-jobs-tab"
          data-bs-toggle="tab"
          data-bs-target="#active-jobs-tab-pane"
          type="button"
          role="tab"
          aria-controls="active-jobs-tab-pane"
          aria-selected="true"
        >
          Active Jobs
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="job-history-tab"
          data-bs-toggle="tab"
          data-bs-target="#job-history-tab-pane"
          type="button"
          role="tab"
          aria-controls="job-history-tab-pane"
          aria-selected="false"
        >
          Jobs History
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="drafted-jobs-tab"
          data-bs-toggle="tab"
          data-bs-target="#drafted-jobs-tab-pane"
          type="button"
          role="tab"
          aria-controls="drafted-jobs-tab-pane"
          aria-selected="false"
        >
          Drafted Jobs
        </button>
      </li>
    </ul>
    <div class="tab-content p-sm-4 p-2" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="active-jobs-tab-pane"
        role="tabpanel"
        aria-labelledby="active-jobs-tab"
        tabindex="0"
      >
        <div class="row g-0 home-emp-dash-recommends">
          <div
            class="container mb-5 pb-3 pt-3"
            v-if="activeJobs.length > 0"
            v-for="job in activeJobs"
            data-bs-toggle="modal"
            data-bs-target="#showJobDetailModal"
            @click="selectItem(job)"
          >
            <div class="row">
              <div class="col-sm-3 mb-sm-0 mb-1">
                <h6 class="mb-1">{{ job.title }}</h6>
              </div>
              <div
                class="col-9 d-flex ms-auto justify-content-end align-items-center text-nowrap"
              >
                <div
                  class="recommend-location d-flex justify-content-center align-items-center"
                >
                  <img
                    class="pe-1"
                    src="@/assets/images/Svg/dash-svg/recommend-location-icon.svg"
                    alt=""
                  />{{ job.location.name }}
                </div>
                <div
                  class="recommend-location mx-2 d-flex justify-content-center align-items-center"
                >
                  <img
                    class="pe-2"
                    src="@/assets/images/Svg/dash-svg/recommend-experience-icon.svg"
                    alt=""
                  />{{ job.experience }} years exp.
                </div>
                <div
                  class="recommend-location d-flex justify-content-center align-items-center"
                >
                  <img
                    class="pe-2"
                    src="@/assets/images/Svg/dash-svg/recommend-fulltime-icon.svg"
                    alt=""
                  />{{ job.type }}
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <span class="text-ellipsis">{{ job.description }}</span>
            </div>
            <div
              class="d-sm-flex justify-content-start align-items-center mt-3"
            >
              <h5
                class="home-emp-dash-recommend-heading mb-0 d-flex align-items-center fw-bold"
              >
                PKR {{ job.salary }}/mo
              </h5>
              <div class="recommend-posted-date-time ps-sm-5">
                <span class="d-flex justify-content-end align-items-center">
                  <img
                    class="me-1"
                    src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                    alt=""
                  />Posted {{ job.posted_at }}
                </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-3 me-2" v-for="media in job.job_media">
                <img
                  v-if="media.media.type == 'Image'"
                  :src="media.media.file_url"
                  style="width: 100%; border-radius: 16px"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <p class="text-white">No Jobs Found...</p>
          </div>
        </div>
      </div>

      <!-- Job History Tab Content -->
      <div
        class="tab-pane fade"
        id="job-history-tab-pane"
        role="tabpanel"
        aria-labelledby="job-history-tab"
        tabindex="0"
      >
        <div class="row g-0 home-emp-dash-recommends">
          <div
            class="container mb-5 pb-3 pt-3"
            v-if="jobHistory.length > 0"
            v-for="job in jobHistory"
            data-bs-toggle="modal"
            data-bs-target="#showJobDetailModal"
            @click="selectItem(job)"
          >
            <div class="row">
              <div class="col-sm-3 mb-sm-0 mb-1">
                <h6 class="mb-1">{{ job.title }}</h6>
              </div>
              <div
                class="col-9 d-flex ms-auto justify-content-end align-items-center text-nowrap"
              >
                <div
                  class="recommend-location d-flex justify-content-center align-items-center"
                >
                  <img
                    class="pe-1"
                    src="@/assets/images/Svg/dash-svg/recommend-location-icon.svg"
                    alt=""
                  />{{ job.location.name }}
                </div>
                <div
                  class="recommend-location mx-2 d-flex justify-content-center align-items-center"
                >
                  <img
                    class="pe-2"
                    src="@/assets/images/Svg/dash-svg/recommend-experience-icon.svg"
                    alt=""
                  />{{ job.experience }} years exp.
                </div>
                <div
                  class="recommend-location d-flex justify-content-center align-items-center"
                >
                  <img
                    class="pe-2"
                    src="@/assets/images/Svg/dash-svg/recommend-fulltime-icon.svg"
                    alt=""
                  />{{ job.type }}
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <span class="text-ellipsis">{{ job.description }}</span>
            </div>
            <div
              class="d-sm-flex justify-content-start align-items-center mt-3"
            >
              <h5
                class="home-emp-dash-recommend-heading mb-0 d-flex align-items-center fw-bold"
              >
                PKR {{ job.salary }}/mo
              </h5>
              <div class="recommend-posted-date-time ps-sm-5">
                <span class="d-flex justify-content-end align-items-center">
                  <img
                    class="me-1"
                    src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                    alt=""
                  />Posted {{ job.posted_at }}
                </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-3 me-2" v-for="media in job.job_media">
                <img
                  v-if="media.media.type == 'Image'"
                  :src="media.media.file_url"
                  style="width: 100%; border-radius: 16px"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <p class="text-white">No Jobs Found...</p>
          </div>
        </div>
      </div>

      <!-- Drafted Jobs Tab Content -->
      <div
        class="tab-pane fade"
        id="drafted-jobs-tab-pane"
        role="tabpanel"
        aria-labelledby="drafted-jobs-tab"
        tabindex="0"
      >
        <div class="row g-0 home-emp-dash-recommends">
          <div
            class="container mb-5 pb-3 pt-3"
            v-if="draftedJobs.length > 0"
            v-for="job in draftedJobs"
            data-bs-toggle="modal"
            data-bs-target="#showJobDetailModal"
            @click="selectItem(job)"
          >
            <div class="row">
              <div class="col-sm-3 mb-sm-0 mb-1">
                <h6 class="mb-1">{{ job.title }}</h6>
              </div>
              <div
                class="col-9 d-flex ms-auto justify-content-end align-items-center text-nowrap"
              >
                <div
                  class="recommend-location d-flex justify-content-center align-items-center"
                >
                  <img
                    class="pe-1"
                    src="@/assets/images/Svg/dash-svg/recommend-location-icon.svg"
                    alt=""
                  />{{ job.location.name }}
                </div>
                <div
                  class="recommend-location mx-2 d-flex justify-content-center align-items-center"
                >
                  <img
                    class="pe-2"
                    src="@/assets/images/Svg/dash-svg/recommend-experience-icon.svg"
                    alt=""
                  />{{ job.experience }} years exp.
                </div>
                <div
                  class="recommend-location d-flex justify-content-center align-items-center"
                >
                  <img
                    class="pe-2"
                    src="@/assets/images/Svg/dash-svg/recommend-fulltime-icon.svg"
                    alt=""
                  />{{ job.type }}
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <span class="text-ellipsis">{{ job.description }}</span>
            </div>
            <div
              class="d-sm-flex justify-content-start align-items-center mt-3"
            >
              <h5
                class="home-emp-dash-recommend-heading mb-0 d-flex align-items-center fw-bold"
              >
                PKR {{ job.salary }}/mo
              </h5>
              <div class="recommend-posted-date-time ps-sm-5">
                <span class="d-flex justify-content-end align-items-center">
                  <img
                    class="me-1"
                    src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                    alt=""
                  />Posted {{ job.posted_at }}
                </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-3 me-2" v-for="media in job.job_media">
                <img
                  v-if="media.media.type == 'Image'"
                  :src="media.media.file_url"
                  style="width: 100%; border-radius: 16px"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <p class="text-white">No Jobs Found...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CreateJob @saved="fetchData"></CreateJob>
  <ShowJobDetail :item="selectedItem" @saved="fetchData"></ShowJobDetail>
</template>
<script>
import CreateJob from "./CreateJob.vue";
import ShowJobDetail from "./ShowJobDetail.vue";

export default {
  components: {
    CreateJob,
    ShowJobDetail,
  },
  data() {
    return {
      loading: false,
      activeJobs: [],
      jobHistory: [],
      draftedJobs: [],
      selectedItem: [],
      profilePic: localStorage.getItem("profilePic"),
    };
  },
  mounted() {
    this.fetchJobs();
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },
    fetchJobs() {
      this.loading = true;
      const id = localStorage.getItem("upId");
      this.$store
        .dispatch("jobs/getByEmployer", { id })
        .then((response) => {
          this.loading = false;
          this.activeJobs = response.data.activeJobs;
          this.draftedJobs = response.data.draftedJobs;
          this.jobHistory = response.data.doneJobs;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
