<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>

    <h5>Feedback / Suggestion</h5>
    <div class="p-sm-5 p-3 custom-card mt-3">
      <form @submit.prevent="submit">
        <div class="form-group mb-3">
          <select class="login-form-control w-100" v-model="type" required>
            <option value="">Select</option>
            <option value="1">Feedback</option>
            <option value="2">Suggestion</option>
          </select>
        </div>
        <div class="form-group">
          <textarea
            class="login-form-control-textarea form-control"
            placeholder="Write a feedback"
            v-model="feedback"
            required
          ></textarea>
        </div>
        <div class="row mt-4">
          <div class="col-md-3">
            <button type="submit" class="basic-info-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      feedback: null,
      type: "",
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      const data = {
        user_id: localStorage.getItem("upId"),
        detail: this.feedback,
        type: this.type,
      };
      this.$store
        .dispatch("feedback/storeAppFeedback", { data })
        .then((response) => {
          this.loading = false;
          this.$toast.show("Feedback successfully saved.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.feedback = null;
          this.type = "";
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
