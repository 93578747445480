<template>
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>
  <div
    class="modal fade"
    id="exampleModal-password-change"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content mt-5">
        <button
          type="button"
          class="modal-cross-btn ms-auto position-relative"
          data-bs-target="#exampleModal-setting"
          data-bs-toggle="modal"
          aria-label="Close"
        >
          <img
            src="@/assets/images/close.png"
            alt=""
          />
        </button>
        <div class="container p-3">
          <form @submit.prevent="submit">
            <h1 class="modal-title fs-5 fw-normal mb-4" id="exampleModalLabel">
              Change Password
            </h1>
            <div class="form-group mb-4">
              <label for="InputPassword1" class="login-form-label"
                >Old Password <span class="text-danger">*</span></label
              >
              <input
                type="password"
                class="login-form-control form-control"
                id="exampleInputPassword1"
                placeholder="Password"
                v-model="password.old_password"
                required
              />
            </div>
            <div class="form-group mb-4">
              <label for="InputPassword1" class="login-form-label"
                >New Password <span class="text-danger">*</span></label
              >
              <input
                type="password"
                class="login-form-control form-control"
                id="exampleInputPassword1"
                placeholder="Password"
                v-model="password.password"
                required
              />
            </div>
            <div class="form-group mb-4">
              <label for="InputPassword1" class="login-form-label"
                >Confirm Password <span class="text-danger">*</span></label
              >
              <input
                type="password"
                class="login-form-control form-control"
                id="exampleInputPassword1"
                placeholder="Password"
                v-model="password.retype_password"
                required
              />
            </div>

            <div class="row g-0 justify-content-between pb-4">
              <div class="col-6 pe-2">
                <button
                  type="submit"
                  class="basic-info-button modal-save-cancel-btn"
                >
                  Save
                </button>
              </div>
              <div class="col-6 ps-2">
                <button
                  type="button"
                  class="basic-info-skip-button modal-save-cancel-btn"
                  data-bs-target="#exampleModal-setting"
                  data-bs-toggle="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      password: {
        old_password: null,
        password: null,
        retype_password: null,
      },
      loading: false,
    };
  },
  methods: {
    submit() {
      if (this.password.password != this.password.retype_password) {
        this.$toast.show("Confirm Password didn't match.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        this.loading = true;
        const data = this.password;
        const id = localStorage.getItem("uid");
        this.$store
          .dispatch("users/changePassword", { data, id })
          .then((response) => {
            this.loading = false;
            if (response.success == false) {
              this.$toast.show(response.error.old_password, {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 5000,
              });
            } else {
              this.$toast.show("Password has been changed.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              Object.keys(this.password).forEach(
                (k) => (this.password[k] = null)
              );
            }
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      }
    },
  },
};
</script>
