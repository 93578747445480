import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Loading from "vue3-loading-overlay";
import { quillEditor } from "vue3-quill";
import Toaster from "@meforma/vue-toaster";
import pusherService from "./helpers/pusher";

import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/style.css";
import "@/assets/css/responsive.css";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import "bootstrap/dist/js/bootstrap.js";

const app = createApp(App);

pusherService.init();

app
  .use(store)
  .use(router)
  .use(Toaster)
  .use(quillEditor)
  .component("loading", Loading)
  .provide("pusherService", pusherService)
  .mount("#app");
