<template>
  <div class="recent-work">
    <div
      class="d-flex justify-content-between px-md-4 px-3 pt-4 pb-3 secondary-light-bg-color border-top-left-radius border-top-right-radius"
    >
      <div class="">
        <h5 class="home-emp-dash-recommend-heading fw-normal mb-0">
          Recently Worked With
        </h5>
      </div>
      <div class="text-end">
        <span class="text-uppercase">View all</span>
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-items-center px-md-4 px-3 pb-4"
    >
      <div class="d-flex align-items-center">
        <div class="position-relative">
          <img
            class="recent-work-img"
            src="@/assets/images/Svg/dash-svg/recent-work-pic-1.svg"
            alt=""
          />
          <img
            class="recent-work-verify position-absolute"
            src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
            alt=""
          />
        </div>
        <div class="ps-xl-3 ps-lg-2 ps-3">
          <h6 class="mb-0">Wade Warren</h6>
          <span class="fw-lighter">Sr. Carpenter</span>
        </div>
      </div>
      <div class="text-end">
        <a class="text-light recommend-posted-date-time link-offset-2" href=""
          >View Profile</a
        >
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-items-center px-md-4 px-3 pb-4"
    >
      <div class="d-flex align-items-center">
        <div class="position-relative">
          <img
            class="recent-work-img"
            src="@/assets/images/Svg/dash-svg/recent-work-pic-2.svg"
            alt=""
          />
        </div>
        <div class="ps-xl-3 ps-lg-2 ps-3">
          <h6 class="mb-0">Jerome Bell</h6>
          <span class="fw-lighter">Car Driver</span>
        </div>
      </div>
      <div class="text-end">
        <a class="text-light recommend-posted-date-time link-offset-2" href=""
          >View Profile</a
        >
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-items-center px-md-4 px-3 pb-4"
    >
      <div class="d-flex align-items-center">
        <div class="position-relative">
          <img
            class="recent-work-img"
            src="@/assets/images/Svg/dash-svg/recent-work-pic-3.svg"
            alt=""
          />
        </div>
        <div class="ps-xl-3 ps-lg-2 ps-3">
          <h6 class="mb-0">Courtney Henry</h6>
          <span class="fw-lighter">Plumber</span>
        </div>
      </div>
      <div class="text-end">
        <a class="text-light recommend-posted-date-time link-offset-2" href=""
          >View Profile</a
        >
      </div>
    </div>
  </div>
</template>
