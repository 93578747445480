<template>
  <div class="container login-container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <!-- Company Sign-up Logo -->
    <div class="login-logo row g-0 justify-content-center">
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <div class="row g-0 text-center">
      <h3 class="login-h3 mb-0">Join us</h3>
      <p class="login-p mb-0">EMP X connects online worker with customer</p>
    </div>
    <div
      class="d-flex justify-content-center align-items-center flex-column mb-5 mt-4"
    >
      <div>
        <img
          :src="previewProfilePic"
          class="rounded-circle previewProfilePic"
          style="width: 150px; height: 150px"
        />
      </div>
      <div class="position-relative mt-3">
        <input
          type="file"
          ref="profilePic"
          class="position-absolute opacity-0"
          accept="image/png, image/jpg, image/jpeg"
          @change="profilePicSelected"
        />
        <button type="button" class="upload-btn">
          <img src="@/assets/images/upload.png" /> Upload Image
        </button>
      </div>
    </div>
    <!-- Company Sign-up Forms -->
    <form @submit.prevent="submit">
      <div class="form-group mb-4">
        <label for="InputEmail1" class="login-form-label" v-if="translations"
          >{{ translations.data["company-name"] }}
          <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="login-form-control form-control"
          id="exampleInputEmail1"
          placeholder="Company Name"
          v-model="company.name"
          required
          autofocus
        />
      </div>
      <div class="form-group mb-4">
        <label for="InputEmail1" class="login-form-label" v-if="translations">{{
          translations.data["phone-number"]
        }}</label>
        <input
          type="text"
          class="login-form-control form-control"
          id="exampleInputEmail1"
          placeholder="+92 321 1234567"
          v-model="company.phone"
          @keydown="numberOnly($event)"
          minlength="11"
          maxlength="11"
        />
      </div>
      <div class="form-group mb-4">
        <label for="InputEmail1" class="login-form-label" v-if="translations">{{
          translations.data["company-website"]
        }}</label>
        <input
          type="text"
          class="login-form-control form-control"
          id="exampleInputEmail1"
          placeholder="info@gmail.com"
          v-model="company.website"
        />
      </div>
      <div class="form-group mb-4">
        <label
          for="InputPassword1"
          class="login-form-label"
          v-if="translations"
          >{{ translations.data["registration-number"] }}</label
        >
        <input
          type="text"
          class="login-form-control form-control"
          id="exampleInputPassword1"
          placeholder="Registration No"
          v-model="company.regNo"
        />
      </div>
      <div class="form-group mb-4">
        <label
          for="InputPassword1"
          class="login-form-label"
          v-if="translations"
          >{{ translations.data["business-category"] }}</label
        >
        <multiselect
          :options="categories"
          :maxOptions="1"
          @items="getCategory"
        ></multiselect>
      </div>
      <div class="form-group mb-4">
        <label class="login-form-label" v-if="translations">{{
          translations.data["registration-document"]
        }}</label>
        <file-input @file="documentSelected"></file-input>
        <img
          v-if="previewDocument"
          :src="previewDocument"
          class="img-thumbnail mt-3"
          style="width: 50%"
        />
      </div>
      <div class="form-group mb-4">
        <label
          for="InputPassword1"
          class="login-form-label"
          v-if="translations"
          >{{ translations.data["profile-summary"] }}</label
        >
        <input
          type="text"
          class="login-form-control form-control"
          id="exampleInputPassword1"
          placeholder="Summary"
          v-model="company.summary"
        />
      </div>
      <!-- Company Sign-up Button -->
      <div class="row g-0 mb-5">
        <button type="submit" class="login-button">Save</button>
      </div>
    </form>
  </div>
</template>
<script>
import { isNumber, validateFileFormat } from "@/helpers/helpers";
import router from "@/router";
import { mapGetters } from "vuex";
import Multiselect from "@/components/Multiselect.vue";
import FileInput from "@/components/FileInput.vue";

export default {
  components: {
    Multiselect,
    FileInput,
  },
  data() {
    return {
      loading: false,
      company: {
        name: null,
        phone: null,
        website: null,
        regNo: null,
        category_id: null,
        profileImage: null,
        userProfileId: localStorage.getItem("upId"),
        document: null,
        summary: null,
      },
      profilePicture: null,
      previewProfilePic: null,
      previewDocument: null,
      regDocument: null,
      categories: [],
    };
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  mounted() {
    this.fetchCategories();
  },
  methods: {
    getCategory(items) {
      this.company.category_id = items[0].id;
    },
    fetchCategories() {
      this.loading = true;
      this.$store
        .dispatch("company/getCategories")
        .then((response) => {
          this.categories = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    documentSelected(file) {
      this.regDocument = file;
      if (validateFileFormat(this.regDocument, "image")) {
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewDocument = e.target.result;
        };
        reader.onloadend = () => {
          this.company.document = reader.result;
        };
        reader.readAsDataURL(this.regDocument);
      }
    },
    profilePicSelected() {
      this.profilePicture = this.$refs.profilePic.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.previewProfilePic = e.target.result;
      };
      reader.onloadend = () => {
        this.company.profileImage = reader.result;
      };
      reader.readAsDataURL(this.profilePicture);
    },
    numberOnly(evt) {
      isNumber(evt);
    },
    submit() {
      this.loading = true;
      const data = this.company;
      this.$store
        .dispatch("company/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show(
              "Company Information has been saved successfully.",
              {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 10000,
              }
            );
            router.push({
              path: "/dashboard/company",
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
