import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    userProfiles({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/chat/user/profiles?receiver_id=${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    userMessages({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(
            `/chat/user/messages?conversation_id=${data.conversation_id}&user_id=${data.user_id}&receiver_id=${data.receiver_id}`
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    sendMessage({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post(`/chat/user/messages`, { data })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
