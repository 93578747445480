<template>
  <div class="recent-work">
    <div class="row px-md-4 px-3 pt-4">
      <h5 class="home-emp-dash-recommend-heading fw-normal mb-0">Message</h5>
    </div>
    <hr />
    <div
      class="d-flex justify-content-between align-items-center px-md-4 px-3 pb-4"
    >
      <div class="d-flex align-items-center">
        <div class="position-relative">
          <img
            class="recent-work-img"
            src="@/assets/images/Svg/dash-svg/recent-work-pic-1.svg"
            alt=""
          />
        </div>
        <div class="ps-xl-3 ps-lg-2 ps-3">
          <h6 class="mb-0">Wade Warren</h6>
          <span class="fw-lighter">Sr. Carpenter</span>
        </div>
      </div>
      <div class="text-end">
        <span class="recommend-posted-date-time" href=""
          >5 min
          <span class="recent-work-msg-notification fw-semibold ms-3"
            >2</span
          ></span
        >
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-items-center px-md-4 px-3 pb-4"
    >
      <div class="d-flex align-items-center">
        <div class="position-relative">
          <img
            class="recent-work-img"
            src="@/assets/images/Svg/dash-svg/recent-work-pic-2.svg"
            alt=""
          />
        </div>
        <div class="ps-xl-3 ps-lg-2 ps-3">
          <h6 class="mb-0">Jerome Bell</h6>
          <span class="fw-lighter">Car Driver</span>
        </div>
      </div>
      <div class="text-end">
        <span class="recommend-posted-date-time" href="">3 hour</span>
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-items-center px-md-4 px-3 pb-4"
    >
      <div class="d-flex align-items-center">
        <div class="position-relative">
          <img
            class="recent-work-img"
            src="@/assets/images/Svg/dash-svg/recent-work-pic-3.svg"
            alt=""
          />
        </div>
        <div class="ps-xl-3 ps-lg-2 ps-3">
          <h6 class="mb-0">Courtney Henry</h6>
          <span class="fw-lighter">Plumber</span>
        </div>
      </div>
      <div class="text-end">
        <span class="recommend-posted-date-time" href="">2 day</span>
      </div>
    </div>
  </div>
</template>
