<template>
  <div
    class="modal fade"
    id="exampleModal-setting"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content mt-5">
        <button
          type="button"
          class="modal-cross-btn ms-auto position-relative"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img
            src="@/assets/images/close.png"
            alt=""
          />
        </button>
        <div class="container p-3 border-radius">
          <h1 class="modal-title fs-5 fw-normal mb-4" id="exampleModalLabel">
            Settings
          </h1>
          <button
            type="button"
            class="btn p-0 text-light w-100"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal-password-change"
          >
            <div class="setting-modal-DIVs d-flex align-items-center">
              <img
                class="ms-sm-5 ms-2"
                src="@/assets/images/Svg/dash-svg/setting-modal-pass-change-icon.svg"
                alt=""
              />
              <span class="ms-3">Change Password</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ChangePassword></ChangePassword>
</template>
<script>
import ChangePassword from "./ChangePassword.vue";

export default {
  components: {
    ChangePassword,
  },
};
</script>
