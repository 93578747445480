<template>
  <div>
    <h5>FAQs</h5>
    <div class="p-md-5 p-sm-4 p-3 custom-card mt-3">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item" v-for="(faq, index) in faqs">
          <h2 class="accordion-header">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#collapse' + faq.id"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              {{ faq.question }}
            </button>
          </h2>
          <div
            :id="'collapse' + faq.id"
            class="accordion-collapse collapse"
            :class="{ show: index == 0 }"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              {{ faq.answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      faqs: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store
        .dispatch("faq/getAll")
        .then((response) => {
          this.faqs = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
