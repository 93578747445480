<template>
  <!-- Edit-Profile Modal -->
  <div
    class="modal fade"
    id="viewCompanyProfileModal"
    tabindex="-1"
    aria-labelledby="viewCompanyProfileModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content mt-5">
        <button
          type="button"
          class="modal-cross-btn ms-auto position-relative"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img
            src="@/assets/images/close.png"
            alt=""
          />
        </button>
        <div class="view-company-prodfile-main container p-sm-4 p-3">
          <div class="row justify-content-center mb-4">
            <div
              class="col-sm-7 d-flex justify-content-sm-end justify-content-center order-1"
            >
              <img src="@/assets/images/Svg/dash-svg/dash-profile-pic.svg" />
            </div>
            <div
              class="col-sm-4 d-flex justify-content-center order-sm-2 order-3"
            >
              <button
                type="button"
                class="home-emp-edit-profile-btn"
                data-bs-toggle="modal"
                data-bs-target="#editCompanyProfileModal"
              >
                <span class="d-flex justify-content-center align-items-center">
                  <img
                    class="pe-1"
                    src="@/assets/images/Svg/dash-svg/home-emp-dash-edit-profile-btn-icon.svg"
                    alt=""
                  />
                  Edit Profile
                </span>
              </button>
            </div>
            <h6
              class="text-center order-sm-3 order-2 mb-sm-0 mb-2"
              v-if="user && user.company"
            >
              {{ user.company.name }}
            </h6>
          </div>
          <div
            class="d-sm-flex justify-content-between text-center mb-sm-5 mb-4"
          >
            <div class="">
              <div class="" v-if="user && user.company">
                {{ user.company.website }}
              </div>
              <div class="">Official Website</div>
            </div>
            <div class="home-emp-dash-line d-sm-block d-none"></div>
            <div class="w-100 d-sm-none d-flex justify-content-center">
              <hr class="w-25" />
            </div>
            <div class="">
              <div class="" v-if="user && user.company">
                {{ user.company.phone }}
              </div>
              <div class="">Phone Number</div>
            </div>
          </div>
          <div class="mb-sm-4 mb-3">
            <h6>Business Category</h6>
            <div class="" v-if="user && user.company.category">
              {{ user.company.category.name }}
            </div>
          </div>
          <div class="mb-sm-4 mb-3">
            <h6>Company Profile Summary</h6>
            <p>
              {{ user.company.summary }}
            </p>
          </div>
          <h5 class="mb-sm-4 mb-3">Employees List</h5>
          <div class="company-employe-list">
            <div
              class="d-flex justify-content-between align-items-center mb-sm-4 mb-3"
              v-for="employee in user.company.employees"
            >
              <div class="d-flex align-items-center">
                <div class="position-relative">
                  <img
                    v-if="employee.profile_pic"
                    class="recent-work-img"
                    :src="emloyee.profile_pic.file_url"
                    alt=""
                  />
                  <img
                    v-else
                    class="recent-work-img"
                    src="@/assets/images/Svg/dash-svg/recent-work-pic-1.svg"
                    alt=""
                  />
                </div>
                <div class="ps-xl-3 ps-lg-2 ps-sm-3 ps-2">
                  <h6 class="mb-0">{{ employee.full_name }}</h6>
                </div>
              </div>
              <div class="text-end">
                <a
                  class="text-light recommend-posted-date-time text-decoration-underline link-offset-2"
                  href=""
                  >View Profile</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EditCompanyProfile :company="user.company" v-if="user"></EditCompanyProfile>
</template>

<script>
import EditCompanyProfile from "./EditCompanyProfile.vue";

export default {
  components: {
    EditCompanyProfile,
  },
  props: {
    user: [],
  },
};
</script>

<style>
.view-company-prodfile-main .home-emp-edit-profile-btn img {
  width: 15px;
  height: 13px;
}

.view-company-prodfile-main .company-employe-list .recent-work-img {
  width: 67px;
  height: 67px;
}

.view-company-prodfile-main .company-employe-list .recent-work-verify {
  width: 18px;
  height: 18px;
}
</style>
