<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>

    <!-- Recommendations-Cards -->
    <div id="posted-jobs">
      <div class="row w-75">
        <h5 class="home-emp-dash-recommend-heading mb-3 fw-normal ms-4 mt-4">
          Search Result
        </h5>
      </div>

      <!-- Job Search -->
      <div class="row g-0 home-emp-dash-recommends p-3" v-if="type == 'worker'">
        <div
          class="container mb-3 p-4"
          v-if="activeJobs.length > 0"
          v-for="job in activeJobs"
          data-bs-toggle="modal"
          data-bs-target="#showJobDetailModal"
          @click="selectItem(job)"
        >
          <div class="row">
            <div class="col-md-12 mb-3">
              <div class="dash-create-job-pic d-flex align-items-center">
                <img
                  src="@/assets/images/Svg/dash-svg/dash-profile-pic.svg"
                  alt=""
                />
                <h4 class="ms-2">{{ job.employer.full_name }}'s Job Post</h4>
              </div>
            </div>
            <div class="col-3">
              <h6 class="mb-0 ps-2">{{ job.title }}</h6>
            </div>
            <div
              class="col-9 d-flex ms-auto justify-content-end align-items-center"
            >
              <div
                class="recommend-location d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-1"
                  src="@/assets/images/Svg/dash-svg/recommend-location-icon.svg"
                  alt=""
                />{{ job.location.name }}
              </div>
              <div
                class="recommend-location mx-2 d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-2"
                  src="@/assets/images/Svg/dash-svg/recommend-experience-icon.svg"
                  alt=""
                />{{ job.experience }} years exp.
              </div>
              <div
                class="recommend-location d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-2"
                  src="@/assets/images/Svg/dash-svg/recommend-fulltime-icon.svg"
                  alt=""
                />{{ job.type }}
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <span>{{ job.description }}</span>
          </div>
          <div class="d-flex justify-content-start align-items-center mt-3">
            <h5
              class="home-emp-dash-recommend-heading mb-0 d-flex align-items-center fw-bold"
            >
              PKR {{ job.salary }}/mo
            </h5>
            <div class="recommend-posted-date-time ps-5">
              <span class="d-flex justify-content-center align-items-center">
                <img
                  class="me-1"
                  src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                  alt=""
                />Posted {{ job.posted_at }}
              </span>
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-md-3 me-2" v-for="media in job.job_media">
              <img
                v-if="media.media.type == 'Image'"
                :src="media.media.file_url"
                style="width: 100%; border-radius: 16px"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <p class="text-white">No Jobs Found...</p>
        </div>
      </div>

      <!-- Worker Serach -->
      <div class="row g-0 home-emp-dash-recommends p-3" v-else>
        <div class="search-dialog p-5">
          <div
            class="d-flex justify-content-between align-items-center"
            v-for="worker in workers"
          >
            <div class="d-flex align-items-center">
              <div class="position-relative">
                <img
                  v-if="worker.profile_pic"
                  class="recent-work-img"
                  :src="worker.profile_pic.media.file_url"
                  alt=""
                />
                <img
                  v-else
                  class="recent-work-img"
                  src="@/assets/images/Svg/dash-svg/recent-work-pic-2.svg"
                  alt=""
                />
              </div>
              <div class="ps-xl-3 ps-lg-2 ps-3">
                <h6 class="mb-0">{{ worker.full_name }}</h6>
              </div>
            </div>
            <div>
              <router-link
                :to="
                  '/dashboard/workerProfile/' + worker.id + '/' + worker.user_id
                "
                class="text-white"
                style="text-decoration: underline"
                >View Profile</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <ShowJobDetail :item="selectedItem"></ShowJobDetail>
  </div>
</template>
<script>
import ShowJobDetail from "@/views/Worker/ShowJobDetail.vue";

export default {
  components: {
    ShowJobDetail,
  },
  data() {
    return {
      loading: false,
      activeJobs: [],
      selectedItem: [],
      searchValue: null,
      type: localStorage.getItem("type"),
      workers: [],
    };
  },
  mounted() {
    this.searchValue = this.$route.params.search;
    if (this.type == "worker") {
      this.fetchJobs();
    } else {
      this.fetchWorkers();
    }
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },
    fetchWorkers() {
      this.loading = true;
      const search = atob(this.searchValue);
      this.$store
        .dispatch("userProfile/searchWorkers", { search })
        .then((response) => {
          console.log(response);
          this.workers = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchJobs() {
      this.loading = true;
      const search = atob(this.searchValue);
      const id = localStorage.getItem("upId");
      this.$store
        .dispatch("jobs/getAll", { id, search })
        .then((response) => {
          this.activeJobs = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.wrapper {
  background: #2b2b2b;
}
header {
  background: #1f1f1f;
}
</style>
