<template>
  <!-- Edit-Profile Modal -->
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>

  <div
    class="modal fade"
    id="editProfileModal"
    tabindex="-1"
    aria-labelledby="editProfileModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content mt-5">
        <button
          type="button"
          class="modal-cross-btn ms-auto position-relative"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img
            src="@/assets/images/close.png"
            alt=""
          />
        </button>
        <form @submit.prevent="submit">
          <div class="container p-md-4 p-sm-3 p-2 text-start">
            <h1
              class="modal-title fs-5 fw-normal text-center pt-sm-0 pt-4"
              id="editProfileModalLabel"
            >
              Basic Information
            </h1>
            <div class="row justify-content-center dash-profile-pic">
              <img
                v-if="previewProfilePic"
                :src="previewProfilePic"
                class="mb-3"
              />
              <img
                v-else
                class="mb-3"
                src="@/assets/images/Svg/dash-svg/dash-profile-pic.svg"
              />
            </div>
            <div class="dropdown text-center mb-4">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  class="d-flex justify-content-center align-items-center h-100"
                >
                  <img
                    class="me-1"
                    src="@/assets/images/Svg/dash-svg/home-emp-modal-photo-icon.svg"
                    alt=""
                  />Edit Photo
                </span>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="javascript:void(0)">
                    <div class="position-relative">
                      <span>Upload Image</span
                      ><input
                        class="position-absolute"
                        type="file"
                        ref="file"
                        accept="image/png, image/jpg, image/jpeg"
                        @change="profilePicSelected(this.$refs.file.files[0])"
                        style="left: 0; opacity: 0"
                      />
                    </div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="javascript:void(0)"
                    >Remove Image</a
                  >
                </li>
              </ul>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations"
                >{{ translations.data["first-name"] }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="basic-info-input form-control"
                placeholder="First Name"
                v-model="user.f_name"
                required
                autofocus
              />
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["last-name"]
              }}</label>
              <input
                type="text"
                class="basic-info-input form-control"
                placeholder="Last Name"
                v-model="user.l_name"
              />
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["father-name"]
              }}</label>
              <input
                type="text"
                class="login-form-control form-control"
                placeholder="Father Name"
                v-model="user.father_name"
              />
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["cnic"]
              }}</label>
              <input
                type="text"
                class="login-form-control form-control"
                placeholder="32100-01236547-9"
                v-model="user.user.cnic"
                @keydown="numberOnly($event)"
                minlength="13"
                maxlength="13"
              />
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["cnic-front-image"]
              }}</label>
              <file-input @file="cnicFrontSelected"></file-input>
              <img
                v-if="previewCnicFront"
                :src="previewCnicFront"
                class="img-thumbnail mt-3"
                style="width: 50%"
              />
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["cnic-back-image"]
              }}</label>
              <file-input @file="cnicBackSelected"></file-input>
              <img
                v-if="previewCnicBack"
                :src="previewCnicBack"
                class="img-thumbnail mt-3"
                style="width: 50%"
              />
            </div>
            <div class="row mb-4">
              <div class="col-md-6 form-group mb-md-0 mb-2">
                <label class="login-form-label" v-if="translations">{{
                  translations.data["dob"]
                }}</label>
                <input
                  type="date"
                  class="login-form-control form-control"
                  v-model="user.dob"
                />
              </div>
              <div class="col-md-6 form-group">
                <label class="login-form-label" v-if="translations">{{
                  translations.data["age"]
                }}</label>
                <input
                  type="text"
                  class="login-form-control form-control"
                  placeholder="25"
                  v-model="user.age"
                  @keydown="numberOnly($event)"
                />
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["phone-number"]
              }}</label>
              <input
                type="text"
                class="login-form-control form-control"
                placeholder="+92 321 1234567"
                v-model="user.user.phone"
                @keydown="numberOnly($event)"
                minlength="11"
                maxlength="11"
              />
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["email"]
              }}</label>
              <input
                type="email"
                class="login-form-control form-control"
                placeholder="info@gmail.com"
                v-model="user.user.email"
              />
            </div>
            <div class="row mb-sm-0 mb-2">
              <span class="login-form-label" v-if="translations">{{
                translations.data["gender"]
              }} <span class="text-danger">*</span></span>
            </div>
            <div class="row g-0 mb-4">
              <div class="col-sm-3">
                <label class="login-form-check">
                  <span
                    class="login-check-span position-relative"
                    v-if="translations"
                    >{{ translations.data["male"] }}</span
                  >
                  <input
                    class="login-form-check-input"
                    type="radio"
                    name="gender"
                    value="Male"
                    v-model="user.gender"
                    id="flexCheckDefault"
                  />
                  <span class="login-checkmark"></span>
                </label>
              </div>
              <div class="col-sm-3 mx-md-4 mx-sm-3 mx-0">
                <label class="login-form-check">
                  <span
                    class="login-check-span position-relative"
                    v-if="translations"
                    >{{ translations.data["female"] }}</span
                  >
                  <input
                    class="login-form-check-input"
                    type="radio"
                    name="gender"
                    value="Female"
                    id="flexCheckDefault"
                    v-model="user.gender"
                  />
                  <span class="login-checkmark"></span>
                </label>
              </div>
              <div class="col-sm-3 mx-md-4 mx-sm-3 mx-0">
                <label class="login-form-check">
                  <span
                    class="login-check-span position-relative"
                    v-if="translations"
                    >{{ translations.data["other"] }}</span
                  >
                  <input
                    class="login-form-check-input"
                    type="radio"
                    name="gender"
                    value="Other"
                    id="flexCheckDefault"
                    v-model="user.gender"
                  />
                  <span class="login-checkmark"></span>
                </label>
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["address"]
              }}</label>
              <input
                type="text"
                class="login-form-control form-control"
                placeholder="32C askari-1 Peshawar, Pakistan"
                v-model="user.address"
              />
            </div>
            <div class="form-group mb-4" v-if="user.type == 'Worker'">
              <label class="login-form-label" v-if="translations">{{
                translations.data["locations-where-you-want"]
              }}</label>
              <multiselect
                :options="locations"
                @items="getLocations"
                :select-options="user.locations"
              ></multiselect>
            </div>
            <div class="form-group mb-4" v-if="user.type == 'Worker'">
              <label class="login-form-label" v-if="translations">{{
                translations.data["skills-profession"]
              }}</label>
              <multiselect
                :options="skills"
                @items="getSkills"
                :select-options="user.skills"
              ></multiselect>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["qualification"]
              }}</label>
              <multiselect
                :options="qualifications"
                :maxOptions="maxQualification"
                @items="getQualification"
                :select-options="[user.qualification]"
              ></multiselect>
            </div>
            <div class="form-group mb-4" v-if="user.type == 'Worker'">
              <label class="login-form-label" v-if="translations">{{
                translations.data["self-intro-video"]
              }}</label>
              <file-input
                :accept="video"
                @file="introVideoSelected"
              ></file-input>
              <video
                class="mt-3 w-50"
                id="video-preview"
                controls
                v-show="previewIntroVideo"
              ></video>
            </div>
            <div class="form-group mb-4" v-if="user.type == 'Worker'">
              <label class="login-form-label" v-if="translations">{{
                translations.data["character-certificate"]
              }}</label>
              <file-input @file="policeCertificateSelected"></file-input>
              <img
                v-if="previewPoliceCertificate"
                :src="previewPoliceCertificate"
                class="img-thumbnail mt-3"
                style="width: 50%"
              />
            </div>
            <div class="form-group mb-4" v-if="user.type == 'Worker'">
              <label class="login-form-label" v-if="translations">{{
                translations.data["skill-certificate"]
              }}</label>
              <file-input @file="skillCertificateSelected"></file-input>
              <img
                v-if="previewSkillCertificate"
                :src="previewSkillCertificate"
                class="img-thumbnail mt-3"
                style="width: 50%"
              />
            </div>
            <!-- Sign-up Button -->
            <div class="row g-3 mb-5">
              <div class="col-md-12">
                <button type="submit" class="basic-info-button">Save</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "@/components/Multiselect.vue";
import FileInput from "@/components/FileInput.vue";
import { isNumber, validateFileFormat } from "@/helpers/helpers";
import { mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
    FileInput,
  },
  props: {
    user: [],
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  data() {
    return {
      skills: [],
      locations: [],
      qualifications: [],
      loading: false,
      profilePicture: null,
      previewProfilePic: null,
      maxQualification: 1,
      cnicFront: null,
      previewCnicFront: null,
      cnicBack: null,
      previewCnicBack: null,
      introVideo: null,
      previewIntroVideo: null,
      policeCertificate: null,
      previewPoliceCertificate: null,
      skillCertificate: null,
      previewSkillCertificate: null,
      video: "video/mp4",
    };
  },
  mounted() {
    if (this.user.type == "Worker") {
      this.fetchSkills();
      this.fetchLocations();
    }
    this.fetchQualification();
    if (this.user) {
      this.user.user_media.forEach((mediaItem) => {
        if (mediaItem.type == "profile") {
          this.previewProfilePic = mediaItem.media.file_url;
        }
        if (mediaItem.type == "cnic_front") {
          this.previewCnicFront = mediaItem.media.file_url;
        }
        if (mediaItem.type == "cnic_back") {
          this.previewCnicBack = mediaItem.media.file_url;
        }
        if (mediaItem.type == "police_certificate") {
          this.previewPoliceCertificate = mediaItem.media.file_url;
        }
        if (mediaItem.type == "skill_certificate") {
          this.previewSkillCertificate = mediaItem.media.file_url;
        }
        if (mediaItem.type == "intro_video") {
          this.previewIntroVideo = mediaItem.media.file_url;
        }
      });
    }
  },
  methods: {
    introVideoSelected(file) {
      let video = document.getElementById("video-preview");
      this.previewIntroVideo = file;
      if (validateFileFormat(this.previewIntroVideo, "video")) {
        this.$refs.introVideo.value = null;
        this.previewIntroVideo = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          video.src = e.target.result;
        };
        reader.onloadend = () => {
          this.user.introVideo = reader.result;
        };
        reader.readAsDataURL(this.previewIntroVideo);
      }
    },
    profilePicSelected(file) {
      this.profilePicture = file;
      if (validateFileFormat(this.profilePicture, "image")) {
        this.$refs.profilePic.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewProfilePic = e.target.result;
        };
        reader.onloadend = () => {
          this.user.profilePic = reader.result;
        };
        reader.readAsDataURL(this.profilePicture);
      }
    },
    cnicFrontSelected(file) {
      this.cnicFront = file;
      if (validateFileFormat(this.cnicFront, "image")) {
        this.$refs.cnicfront.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewCnicFront = e.target.result;
        };
        reader.onloadend = () => {
          this.user.cnicFrontImage = reader.result;
        };
        reader.readAsDataURL(this.cnicFront);
      }
    },
    cnicBackSelected(file) {
      this.cnicBack = file;
      if (validateFileFormat(this.cnicBack, "image")) {
        this.$refs.cnicback.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewCnicBack = e.target.result;
        };
        reader.onloadend = () => {
          this.user.cnicBackImage = reader.result;
        };
        reader.readAsDataURL(this.cnicBack);
      }
    },
    policeCertificateSelected(file) {
      this.policeCertificate = file;
      if (validateFileFormat(this.policeCertificate, "image")) {
        this.$refs.policeCertificate.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewPoliceCertificate = e.target.result;
        };
        reader.onloadend = () => {
          this.user.policeCertificate = reader.result;
        };
        reader.readAsDataURL(this.policeCertificate);
      }
    },
    skillCertificateSelected(file) {
      this.skillCertificate = file;
      if (validateFileFormat(this.skillCertificate, "image")) {
        this.$refs.skillCertificate.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewSkillCertificate = e.target.result;
        };
        reader.onloadend = () => {
          this.user.skillCertificate = reader.result;
        };
        reader.readAsDataURL(this.skillCertificate);
      }
    },
    getLocations(items) {
      this.user.locations = items;
    },
    getSkills(items) {
      this.user.skills = items;
    },
    getQualification(items) {
      this.user.qualifications = items;
    },
    numberOnly(evt) {
      isNumber(evt);
    },
    fetchSkills() {
      this.loading = true;
      this.$store
        .dispatch("skills/getAll")
        .then((response) => {
          this.skills = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchLocations() {
      this.loading = true;
      this.$store
        .dispatch("locations/getAll")
        .then((response) => {
          this.locations = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchQualification() {
      this.loading = true;
      this.$store
        .dispatch("qualifications/getAll")
        .then((response) => {
          this.qualifications = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      const data = this.user;
      const id = localStorage.getItem("upId");
      this.$store
        .dispatch("userProfile/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("User Information has been saved successfully.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 10000,
            });
          } else {
            this.$toast.show("Failed to save. Please try again.", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
