<template>
  <div class="login-container px-sm-2 px-3 mx-auto">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <!-- Login Logo -->
    <div class="login-logo row g-0 justify-content-center">
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <div class="row g-0 text-center mb-sm-0 mb-5">
      <h3 class="login-h3 mb-0">New Password</h3>
      <p class="login-p mb-sm-4 mb-0">Enter your new password.</p>
    </div>
    <!-- Login Forms -->
    <form @submit.prevent="submit">
      <span class="text-success" v-if="msg">{{ msg }}</span>
      <div class="form-group mb-sm-4 mb-3">
        <label class="login-form-label">
          New Password
          <span class="text-danger">*</span></label
        >
        <span class="text-danger d-block">
          <template v-if="errors.username">
            {{ errors.username[0] }}
          </template>
          <template v-else>
            {{ errors.login }}
          </template>
        </span>
        <input
          type="password"
          class="login-form-control form-control"
          placeholder="****"
          v-model="password"
          required
          autofocus
          autocomplete="off"
        />
      </div>
      <div class="form-group mb-sm-4 mb-3">
        <label class="login-form-label">
          Confirm Password
          <span class="text-danger">*</span></label
        >
        <span class="text-danger d-block">
          <template v-if="errors.username">
            {{ errors.username[0] }}
          </template>
          <template v-else>
            {{ errors.login }}
          </template>
        </span>
        <input
          type="password"
          class="login-form-control form-control"
          placeholder="****"
          v-model="reTypePassword"
          required
          autofocus
          autocomplete="off"
        />
      </div>
      <!-- Login Button -->
      <div class="mb-sm-5 mb-4">
        <button type="submit" class="login-button">Submit</button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  data() {
    return {
      loading: false,
      password: null,
      reTypePassword: null,
      errors: [],
      msg: null,
    };
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  methods: {
    submit() {
      this.msg = null;
      if (this.reTypePassword != this.password) {
        this.msg = "Password didn't match";
      } else {
        this.loading = true;
        const id = localStorage.getItem("uid");
        const data = {
          password: this.password,
        };
        this.$store
          .dispatch("users/newPassword", { data, id })
          .then((response) => {
            this.loading = false;
            if (response.success == true) {
              router.push("/role");
              localStorage.clear();
            }
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      }
    },
  },
};
</script>
