<template>
  <div class="header-bg px-4 py-2">
    <div class="d-flex">
      <input
        type="text"
        class="employer-dash-search-input w-100"
        placeholder="Search for Jobs..."
      />
      <li
        class="nav-item dropdown ms-3 d-flex justify-content-center align-items-center"
      >
        <a
          class="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="@/assets/images/Svg/dash-svg/emp-dash-select-icon.svg"
            style="margin-top: -6px"
          />
        </a>
      </li>
    </div>
  </div>
</template>
