<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div
      class="modal fade"
      id="showJobDetailModal"
      tabindex="-1"
      aria-labelledby="showJobDetailModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content mt-5">
          <button
            type="button"
            class="modal-cross-btn ms-auto z-1 position-absolute"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <img src="@/assets/images/close.png" alt="" />
          </button>
          <div>
            <h1
              class="modal-title fs-5 fw-normal mb-sm-4 mb-1"
              id="showJobDetailModalLabel"
              v-if="item.employer"
            >
              <div class="dash-create-job-pic d-flex align-items-center">
                <img
                  src="@/assets/images/Svg/dash-svg/dash-profile-pic.svg"
                  alt=""
                />
                <h4 class="ms-sm-2 mb-0 text-white">
                  {{ item.employer.full_name }}'s Job Post
                </h4>
              </div>
            </h1>
            <div
              class="row g-0 home-emp-dash-recommends position-relative p-sm-4 p-3 job-detail-card"
            >
              <div class="">
                <div class="row">
                  <div class="col-lg-4 d-flex align-items-center">
                    <h6 class="fs-5">{{ item.title }}</h6>
                  </div>
                  <div
                    class="col-lg-7 d-flex ms-auto justify-content-end align-items-center"
                  >
                    <div
                      class="recommend-location my-posted-jobs-modal-icon-img d-flex justify-content-center align-items-center"
                    >
                      <img
                        class="me-1"
                        src="@/assets/images/Svg/dash-svg/recommend-location-icon.svg"
                        alt=""
                      /><span v-if="item.location">{{
                        item.location.name
                      }}</span>
                    </div>
                    <div
                      class="recommend-location my-posted-jobs-modal-icon-img mx-3 d-flex justify-content-center align-items-center"
                    >
                      <img
                        class="me-2"
                        src="@/assets/images/Svg/dash-svg/recommend-experience-icon.svg"
                        alt=""
                      />{{ item.experience }} years exp.
                    </div>
                    <div
                      class="recommend-location my-posted-jobs-modal-icon-img d-flex justify-content-center align-items-center"
                    >
                      <img
                        class="me-2"
                        src="@/assets/images/Svg/dash-svg/recommend-fulltime-icon.svg"
                        alt=""
                      />{{ item.type }}
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <span>{{ item.description }}</span>
                </div>
                <div
                  class="d-flex justify-content-between align-items-center pt-4 pb-3"
                >
                  <h5
                    class="home-emp-dash-recommend-heading mb-0 d-flex align-items-center fw-bold"
                  >
                    PKR {{ item.salary }}/mo
                  </h5>
                  <div class="recommend-posted-date-time">
                    <span
                      class="my-posted-jobs-modal-date-time-img d-flex justify-content-center align-items-center"
                    >
                      <img
                        class=""
                        src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                        alt=""
                      />Posted {{ item.posted_at }}
                    </span>
                  </div>
                </div>
                <div class="row mt-3">
                  <div
                    class="col-lg-3 col-6 me-2"
                    v-for="media in item.job_media"
                  >
                    <img
                      v-if="media.media.type == 'Image'"
                      :src="media.media.file_url"
                      style="width: 100%; border-radius: 16px"
                    />
                  </div>
                </div>
                <div class="row mb-3" v-for="media in item.job_media">
                  <audio
                    class="col-lg-6"
                    v-if="media.media.type == 'Audio'"
                    controls
                    :src="media.media.file_url"
                  ></audio>
                </div>
                <div
                  v-if="
                    item.salary_type == 'Negotiable' && item.status == 'Posted'
                  "
                  class="negotite-box p-3"
                >
                  <h5 v-if="translations" class="text-white">
                    {{ translations.data["negotiation"] }}
                  </h5>
                  <div class="form-group mb-3">
                    <label v-if="translations" class="mb-2 text-white">{{
                      translations.data["rate"]
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="PKR"
                      v-model="rate"
                      @keydown="numberOnly($event)"
                    />
                  </div>
                  <div class="form-group">
                    <label v-if="translations" class="mb-2 text-white">{{
                      translations.data["comment"]
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Comment"
                      v-model="comment"
                    />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-3" v-if="!item.application_status">
                    <button
                      class="home-emp-edit-profile-btn"
                      @click="applyJob(item.id)"
                    >
                      Apply
                    </button>
                  </div>
                  <div
                    class="col-md-6"
                    v-if="item.application_status == 'Applied'"
                  >
                    <p>You have already applied to this job</p>
                  </div>
                  <div
                    class="col-md-3"
                    v-if="item.application_status == 'Hired'"
                  >
                    <button
                      class="success-btn d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="@/assets/images/hire.png"
                        alt=""
                        style="width: 15px; height: 15px"
                      />
                      <span class="ms-2">Hired</span>
                    </button>
                  </div>
                  <div
                    class="col-md-3"
                    v-if="item.application_status == 'Completed'"
                  >
                    <button
                      class="home-emp-edit-profile-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#feedbackModal"
                      v-if="!item.feedback"
                    >
                      Give Review
                    </button>
                  </div>
                  <div class="col-md-6" v-if="item.feedback">
                    <p>You have already gave review to this job.</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h4 class="mb-3 mt-3 text-white">Reviews</h4>
              <div
                class="review-card p-3 mb-3"
                v-for="feedback in item.feedbacks"
              >
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <div class="dash-create-job-pic d-flex align-items-center">
                      <img
                        v-if="feedback.worker && feedback.worker.profile_pic"
                        :src="feedback.worker.profile_pic.media.file_url"
                        class="profile_pic"
                      />
                      <img
                        v-else-if="
                          feedback.employer && feedback.employer.profile_pic
                        "
                        :src="feedback.employer.profile_pic.media.file_url"
                        class="profile_pic"
                      />
                      <img
                        v-else
                        src="@/assets/images/Svg/dash-svg/dash-profile-pic.svg"
                        class="profile_pic"
                      />
                      <h6
                        class="ms-2 text-black"
                        v-if="feedback.feedback_by == 'Worker'"
                      >
                        {{ feedback.worker.full_name }} | Worker
                      </h6>
                      <h6
                        class="ms-2 text-black"
                        v-if="feedback.feedback_by == 'Employer'"
                      >
                        {{ feedback.employer.full_name }} | Employer
                      </h6>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="recommend-posted-date-time">
                      <span
                        class="my-posted-jobs-modal-date-time-img d-flex justify-content-end align-items-center text-black"
                      >
                        <img
                          class=""
                          src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                          alt=""
                        />Posted
                        {{ feedback.posted_at }}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <p class="text-black mt-3">{{ feedback.detail }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Feedback modal -->
  <div
    class="modal fade"
    id="feedbackModal"
    tabindex="-1"
    aria-labelledby="feedbackModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content p-sm-3">
        <button
          type="button"
          class="modal-cross-btn ms-auto position-relative"
          data-bs-toggle="modal"
          data-bs-target="#showJobDetailModal"
        >
          <img src="@/assets/images/close.png" alt="" />
        </button>
        <div class="p-sm-3 p-2 review-modal">
          <h1 class="modal-title fs-5 fw-normal mb-4" id="feedbackModalLabel">
            Give your Feedback
          </h1>
          <form @submit.prevent="submit">
            <div class="form-group mb-3">
              <label class="mb-2" v-if="translations"
                >{{ translations.data["title"] }}
              </label>
              <input
                type="text"
                class="basic-info-input form-control"
                placeholder="Title"
                v-model="feedback.title"
              />
            </div>
            <div class="form-group mb-sm-3 mb-4">
              <label class="mb-2" v-if="translations"
                >{{ translations.data["details"] }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="basic-info-input form-control"
                placeholder="Details"
                v-model="feedback.detail"
                required
              />
            </div>
            <div class="row g-0 justify-content-between pb-4">
              <div class="col-sm-6 col-8 pe-sm-2 pe-1">
                <button
                  type="submit"
                  class="basic-info-button modal-save-cancel-btn"
                >
                  Submit
                </button>
              </div>
              <div class="col-sm-6 col-4 ps-sm-2 ps-1">
                <button
                  type="button"
                  class="basic-info-skip-button modal-save-cancel-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#showJobDetailModal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { isNumber } from "@/helpers/helpers";
import router from "@/router";

export default {
  props: {
    item: [],
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  data() {
    return {
      type: localStorage.getItem("type"),
      loading: false,
      rate: null,
      comment: null,
      feedback: {
        title: null,
        detail: null,
        rating: null,
        job_id: null,
        worker_id: localStorage.getItem("upId"),
        employer_id: null,
      },
    };
  },
  methods: {
    numberOnly(evt) {
      isNumber(evt);
    },
    applyJob(id) {
      this.loading = true;
      const data = {
        workerId: localStorage.getItem("upId"),
        jobId: id,
        status: "Applied",
        rate: this.rate,
        comment: this.comment,
      };
      this.$store
        .dispatch("applications/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfully applied to job.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.rate = null;
            this.comment = null;
            this.$emit("saved");
            router.go(0);
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    submit() {
      this.loading = true;
      this.feedback.job_id = this.item.id;
      this.feedback.employer_id = this.item.employer_id;
      this.feedback.feedback_by = "Worker";
      const data = this.feedback;
      this.$store
        .dispatch("feedback/store", { data })
        .then((response) => {
          this.loading = false;
          this.$toast.show("Feedback successfully submitted.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.feedback.title = null;
          this.feedback.detail = null;
          router.go(0);
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>

<style>
.negotite-box .form-control {
  border-radius: 50px;
}
</style>
