<template>
  <div class="header-bg p-4 profile-mobile-main text-center rounded-4">
    <div class="d-flex justify-content-center">
      <div
        class="dash-profile-pic mb-2 justify-content-center position-relative w-auto"
      >
        <img src="@/assets/images/Svg/dash-svg/dash-profile-pic.svg" alt="" />
        <img
          class="profile-verify position-absolute"
          src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
          alt=""
        />
      </div>
    </div>
    <div>
      <h6 class="mb-0">{{ user?.full_name }}</h6>
    </div>
    <div>
      <p class="fw-lighter">{{ user?.type }}</p>
    </div>
    <div class="d-flex justify-content-center">
      <hr class="w-25" />
    </div>
    <div class="d-flex flex-column">
      <span class="fw-semibold text-center">Location</span>
      <span class="fw-lighter text-center">{{ user?.address }}</span>
    </div>
    <div class="d-flex justify-content-center">
      <hr class="w-25" />
    </div>
    <div class="d-flex flex-column align-items-center">
      <span class="fw-semibold">{{ user?.user?.email }}</span>
      <span class="fw-lighter mb-2">Email</span>
      <span class="fw-semibold">{{ user?.user?.phone }}</span>
      <span class="fw-lighter mb-3">Phone Number</span>
      <li class="nav-item-profile-status dropdown-profile-status">
        <a
          class="nav-link dropdown-toggle text-center h-100"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="line-height: 35px"
        >
          {{ user?.status }}
        </a>
        <!-- <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Available</a></li>
            <li><a class="dropdown-item" href="#">Not Available</a></li>
          </ul> -->
      </li>
    </div>
    <div class="d-flex justify-content-center">
      <hr class="w-25" />
    </div>
    <div class="d-flex flex-column">
      <span class="fw-semibold">Experience</span>
      <span class="fw-lighter mb-3">{{ user?.experience }} Years</span>
      <span class="fw-semibold">Skills</span>
      <span class="fw-lighter mb-2" v-if="user"
        ><span v-for="(skill, index) of user.skills"
          >{{ skill.name
          }}<span v-if="index < user.skills.length - 1">, </span></span
        ></span
      >
    </div>
    <div class="d-flex justify-content-center">
      <hr class="w-25" />
    </div>
    <!-- <div class="col-md-2" v-if="type != 'worker' || type != 'company'"></div> -->
    <!-- Button trigger modal -->
    <div class="d-flex flex-column align-items-center">
      <button
        class="home-emp-edit-profile-btn mb-2"
        data-bs-toggle="modal"
        data-bs-target="#editProfileModal"
      >
        <span class="d-flex justify-content-center align-items-center">
          <img
            class="pe-1"
            src="@/assets/images/Svg/dash-svg/home-emp-dash-edit-profile-btn-icon.svg"
            alt=""
          />Edit Profile
        </span>
      </button>
      <button
        class="home-emp-add-ref-btn mb-2"
        data-bs-toggle="modal"
        data-bs-target="#editReferencesModal"
      >
        <span class="d-flex justify-content-center align-items-center">
          <img class="pe-2" src="@/assets/images/reference.png" alt="" />Add
          References
        </span>
      </button>
      <button
        class="home-emp-add-ref-btn"
        data-bs-toggle="modal"
        data-bs-target="#editExperiencesModal"
      >
        <span class="d-flex justify-content-center align-items-center">
          <img class="pe-2" src="@/assets/images/experience.png" alt="" />Add
          Experience
        </span>
      </button>
    </div>
    <!-- Edit Profile Modal -->
    <EditProfile v-if="user" :user="user"></EditProfile>
    <EditReferences
      :user="user"
      v-if="user && (type == 'worker' || type == 'employer')"
    ></EditReferences>
    <EditExperiences
      :user="user"
      v-if="user && type == 'worker'"
    ></EditExperiences>
    <EditCompanyProfile
      :user="user"
      v-if="user && type == 'company'"
    ></EditCompanyProfile>
  </div>
</template>

<script>
import EditProfile from "@/components/modals/EditProfile.vue";
import EditReferences from "@/components/modals/EditReferences.vue";
import EditExperiences from "@/components/modals/EditExperiences.vue";
import EditCompanyProfile from "@/components/modals/EditCompanyProfile.vue";

export default {
  components: {
    EditProfile,
    EditReferences,
    EditExperiences,
    EditCompanyProfile,
  },
  data() {
    return {
      loading: false,
      user: null,
      userId: null,
      type: null,
      profileImage: null,
      skills: [],
    };
  },
  created() {
    this.userId = localStorage.getItem("uid");
    this.type = localStorage.getItem("type");
  },
  mounted() {
    this.userMe();
  },
  methods: {
    userMe() {
      this.loading = true;
      const id = this.userId;
      const type = this.type;
      this.$store
        .dispatch("userProfile/getSingle", { id, type })
        .then((response) => {
          this.loading = false;
          this.user = response.data;
          this.user.user_media.forEach((mediaItem) => {
            if (mediaItem.type == "profile") {
              this.profileImage = mediaItem.media.file_url;
            }
          });
          localStorage.setItem("upId", this.user.id);
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
