<template>
  <section class="green-section">
    <div class="container" id="role-selection">
      <!-- Role Logo -->
      <div class="row g-0 justify-content-center role-logo">
        <img
          class=""
          src="@/assets/images/logo.png"
          alt=""
        />
      </div>
      <div class="row g-0 text-center">
        <h3 class="role-login-same-h3 role-h3 mb-0 text-white">Welcome!</h3>
        <p class="role-login-same-p role-p text-white">
          EMP X connects online worker with customer
        </p>
      </div>
      <!-- Cards -->
      <div class="row g-0 justify-content-center">
        <div class="col-lg-3">
          <div class="user-type-card ms-lg-auto mx-auto">
            <div class="b-start row g-0"></div>
            <router-link to="/login/worker">
              <div
                class="main-body mx-auto justify-content-center row g-0 position-relative"
              >
                <img
                  src="@/assets/images/Svg/login-svg/role-worker.png"
                  alt=""
                />
                <h5 class="text-center mb-0 text-uppercase text-white">
                  i am worker
                </h5>
              </div>
            </router-link>
            <div class="b-end row g-0 position-relative"></div>
          </div>
        </div>
        <div
          class="seperator col-lg-1 d-flex flex-lg-column justify-content-center align-items-center position-relative"
        >
          <span class="line"></span>
          <span class="login-line d-lg-none d-block w-25"></span>
          <span
            class="OR d-flex justify-content-center align-items-center mx-lg-0 mx-2"
            >Or</span
          >
          <span class="line"></span>
          <span class="login-line d-lg-none d-block w-25"></span>
        </div>
        <div class="col-lg-3">
          <div class="user-type-card mx-auto">
            <div class="b-start row g-0"></div>
            <router-link to="/login/employer">
              <div
                class="main-body mx-auto justify-content-center row g-0 position-relative"
              >
                <img
                  src="@/assets/images/Svg/login-svg/role-employer.png"
                  alt=""
                />
                <h5 class="text-center mb-0 text-uppercase text-white">
                  i am Employer
                </h5>
                <span class="text-center text-white">(Looking for Worker)</span>
              </div>
            </router-link>
            <div class="b-end row g-0 position-relative"></div>
          </div>
        </div>
        <div
          class="seperator-2 col-lg-1 d-flex flex-lg-column justify-content-center align-items-center position-relative"
        >
          <span class="line"></span>
          <span class="login-line d-lg-none d-block w-25"></span>
          <span
            class="OR role-OR d-flex justify-content-center align-items-center mx-lg-0 mx-2"
            >Or</span
          >
          <span class="line"></span>
          <span class="login-line d-lg-none d-block w-25"></span>
        </div>
        <div class="col-lg-3">
          <div class="user-type-card me-lg-auto mx-auto">
            <div class="b-start row g-0"></div>
            <router-link to="/login/company">
              <div
                class="main-body mx-auto justify-content-center row g-0 position-relative"
              >
                <img
                  src="@/assets/images/Svg/login-svg/role-company.png"
                  alt=""
                />
                <h5 class="text-center mb-0 text-uppercase text-white">
                  as company
                </h5>
              </div>
            </router-link>
            <div class="b-end row g-0 position-relative"></div>
          </div>
        </div>
      </div>
      <!-- Help Link -->
      <!-- <div class="row">
        <h4 class="role-h4 text-center">
          Need a help <span class="help-icon d-inline-block">?</span>
        </h4>
      </div> -->
    </div>
  </section>
</template>
<script>
import router from "@/router";

export default {
  beforeCreate() {
    const lastRoute = localStorage.getItem("lastRoute");
    const type = localStorage.getItem("type");
    if (lastRoute) {
      router.push({ name: lastRoute, params: { type: type } });
    }
  },
};
</script>
<style scoped>
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .container {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 30px;
  }
}
</style>
