<template>
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>
  <div class="p-4 primary-bg-color mb-3 survey-form-msg">
    <p class="ps-5 pe-5 text-center">
      {{ translations?.data["survey-box-text"] }}
    </p>
    <div class="d-flex justify-content-center">
      <router-link :to="`/survey-form/${surveyForm.encrypted_form_id}`" class="survey-btn me-3 text-center"
        >Take Survey</router-link
      >
      <button class="survey-skip-btn" @click="skipSurvey">Skip</button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    surveyForm: null,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  methods: {
    skipSurvey() {
      this.loading = true;
      const id = this.surveyForm.id;
      this.$store
        .dispatch("form/skip", { id })
        .then((response) => {
            this.$emit("skipped");
            this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
