<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <h5>Support</h5>
    <div class="p-md-5 p-sm-4 p-3 custom-card mt-3">
      <form @submit.prevent="submit">
        <div class="form-group mb-3">
          <file-input @file="documentSelected"></file-input>
          <img
            v-if="previewDocument"
            :src="previewDocument"
            class="img-thumbnail mt-3"
            style="width: 50%"
          />
        </div>
        <div class="form-group">
          <textarea
            class="login-form-control-textarea form-control"
            placeholder="Write a feedback"
            v-model="detail"
            required
          ></textarea>
        </div>
        <div class="row mt-4">
          <div class="col-md-3">
            <button type="submit" class="basic-info-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import FileInput from "@/components/FileInput.vue";
import { validateFileFormat } from "@/helpers/helpers";

export default {
  components: {
    FileInput,
  },
  data() {
    return {
      loading: false,
      previewDocument: null,
      regDocument: null,
      file: null,
      userId: localStorage.getItem("upId"),
      detail: null,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      const data = {
        userId: this.userId,
        detail: this.detail,
        file: this.file,
      };
      this.$store
        .dispatch("support/store", { data })
        .then((response) => {
          this.loading = false;
          this.$toast.show("Support successfully saved.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.detail = null;
          this.previewDocument = null;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.$toast.show("Failed to save.", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 5000,
          });
        });
    },
    documentSelected(file) {
      this.regDocument = file;
      if (validateFileFormat(this.regDocument, "image")) {
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewDocument = e.target.result;
        };
        reader.onloadend = () => {
          this.file = reader.result;
        };
        reader.readAsDataURL(this.regDocument);
      }
    },
  },
};
</script>
