<template>
  <div>
    <footer class="pt-3 pb-3">
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            <ul>
              <li>
                <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#inviteModal">Invite a friend</a>
              </li>
              <li>
                <router-link to="/faq">FAQ's</router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <ul>
              <li>
                <router-link to="/feedback">Feedback & Suggestion</router-link>
              </li>
              <li>
                <router-link to="/privacy-policy">Privacy Policy</router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <ul>
              <li>
                <router-link to="/terms-and-conditions">Terms & Conditions</router-link>
              </li>
              <li>
                <router-link to="/support">Support</router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-2">
            <ul>
              <li>
                <router-link to="/contact-us">Contact us</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>

    <div class="modal fade" id="inviteModal" tabindex="-1" aria-labelledby="inviteModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content header-bg">
          <button type="button" class="modal-cross-btn ms-auto position-relative top-0" data-bs-dismiss="modal"
            aria-label="Close">
            <img src="@/assets/images/Svg/dash-svg/modal-cross-btn-pic.svg" alt="" />
          </button>

          <div class="px-sm-4 px-3 pb-sm-5 pb-4">
            <h5>Invite a Friend</h5>
            <p>Share</p>
            <div class="d-flex justify-content-start flex-wrap">
              <div class="d-flex flex-column justify-content-center align-items-center me-3">
                <img src="@/assets/images/whatsapp.png" style="width: 45px; height: 45px" />
                <span style="font-size: 12px" class="mt-2">WhatsApp</span>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center me-3">
                <img src="@/assets/images/facebook.png" style="width: 45px; height: 45px" />
                <span style="font-size: 12px" class="mt-2">Facebook</span>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center me-3">
                <img src="@/assets/images/twitter.png" style="width: 45px; height: 45px" />
                <span style="font-size: 12px" class="mt-2">X</span>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center me-3">
                <img src="@/assets/images/linkedin.png" style="width: 45px; height: 45px" />
                <span style="font-size: 12px" class="mt-2">LinkedIn</span>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center pt-sm-0 pt-2 me-3">
                <img src="@/assets/images/email.png" style="width: 45px; height: 45px" />
                <span style="font-size: 12px" class="mt-2">Email</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
