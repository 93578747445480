<template>
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>
  <div class="p-4 primary-bg-color mb-3 survey-form-msg">
    <h5 class="mb-4 text-white">{{ form?.title }}</h5>
    <form @submit.prevent="submit">
      <div class="form-group mb-3" v-for="question in form?.questions">
        <h6 class="mb-3 text-white">
          {{ question.title }}
          <span class="text-danger d-block" v-if="question.is_required == 1"
            ><small>Required</small></span
          >
        </h6>
        <template v-if="question.type == 'checkbox'">
          <label class="login-form-check" v-for="option in question.options">
            <span class="login-check-span position-relative text-white">{{
              option.title
            }}</span>
            <input
              class="login-form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              :name="question.id"
              :required="question.is_required"
              :value="option.id"
              true-value="1"
              false-value="0"
              @change="selectOption(question.id, option.id, $event.target)"
            />
            <span class="survey-checkmark"></span>
          </label>
        </template>
        <template v-if="question.type == 'radio'">
          <label
            class="login-form-radio position-relative d-block"
            v-for="option in question.options"
          >
            <input
              class="login-form-radio-input me-3"
              type="radio"
              id="flexCheckDefault"
              :name="question.id"
              :required="question.is_required"
              :value="option.id"
              true-value="1"
              false-value="0"
              @change="selectOption(question.id, option.id, $event.target)"
            />
            <span class="survey-radio"></span>
            <span class="login-radio-span position-relative text-white">{{
              option.title
            }}</span>
          </label>
        </template>
        <template v-if="question.type == 'text'">
          <input
            type="text"
            class="survey-text"
            :required="question.is_required"
            true-value="1"
            false-value="0"
            @keyup="selectOption(question.id, null, $event.target)"
          />
        </template>
      </div>
      <div class="">
        <button type="submit" class="survey-btn me-3 text-center">
          Submit
        </button>
        <button class="survey-skip-btn" @click="$router.go(-1)">Cancel</button>
      </div>
    </form>
  </div>
</template>

<script>
import router from "@/router";

export default {
  data() {
    return {
      loading: false,
      formId: null,
      form: null,
      answers: [],
      answer: {
        user_id: null,
        form_id: null,
        question_id: null,
        answer: null,
      },
      checkValue: null,
      radioValue: null,
    };
  },
  mounted() {
    this.formId = this.$route.params.id;
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      const id = this.formId;
      this.$store
        .dispatch("form/getForm", { id })
        .then((response) => {
          this.loading = false;
          this.form = response.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    submit() {
      this.loading = true;
      const data = this.answers;
      this.$store
        .dispatch("form/submitForm", { data })
        .then((response) => {
          this.loading = false;
          this.$toast.show("Survey successfully saved.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          router.go(-1);
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    selectOption(questionId, optionId = null, answer = null) {
      if (answer.type == "checkbox") {
        const index = this.answers.findIndex(
          (x) => x.question_id == questionId
        );
        if (answer.checked == false) {
          if (index > -1) {
            this.answers[index].answer.splice(
              this.answers[index].answer.indexOf(optionId),
              1
            );
            if (this.answers[index].answer.length < 1) {
              this.answers.splice(index, 1);
            }
          }
        } else {
          if (index > -1) {
            this.answers[index].answer.push(optionId);
          } else {
            this.answer.answer = [];
            this.answer.user_id = localStorage.getItem("upId");
            this.answer.form_id = this.formId;
            this.answer.question_id = questionId;
            this.answer.answer.push(optionId);
            this.answers.push(Object.assign({}, this.answer));
          }
        }
        console.log(this.answers);
      }

      if (answer.type == "radio") {
        if (answer.checked == true) {
          const index = this.answers.findIndex(
            (x) => x.question_id == questionId
          );
          if (index > -1) {
            this.answers[index].option_id = optionId;
          } else {
            this.answer.user_id = localStorage.getItem("upId");
            this.answer.form_id = this.formId;
            this.answer.question_id = questionId;
            this.answer.answer = optionId;
            this.answers.push(Object.assign({}, this.answer));
          }
        }
      }

      if (answer.type == "text") {
        const index = this.answers.findIndex(
          (x) => x.question_id == questionId
        );
        if (index > -1) {
          this.answers[index].answer = answer.value;
        } else {
          this.answer.user_id = localStorage.getItem("upId");
          this.answer.form_id = this.formId;
          this.answer.question_id = questionId;
          this.answer.answer = answer.value;
          this.answers.push(Object.assign({}, this.answer));
        }
      }
    },
  },
};
</script>
