<template>
  <div>
    <div id="posted-jobs">
      <div class="row w-75">
        <h5 class="home-emp-dash-recommend-heading mb-3 fw-normal ms-4 mt-4">
          Posted Jobs
        </h5>
      </div>
      <div class="row g-0 home-emp-dash-recommends p-3">
        <div
          class="container mb-3 p-4"
          v-if="activeJobs.length > 0"
          v-for="job in activeJobs"
          data-bs-toggle="modal"
          data-bs-target="#showJobDetailModal"
          @click="selectItem(job)"
        >
          <div class="row">
            <div class="col-3">
              <h6 class="mb-0 ps-2">{{ job.title }}</h6>
            </div>
            <div
              class="col-9 d-flex ms-auto justify-content-end align-items-center"
            >
              <div
                class="recommend-location d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-1"
                  src="@/assets/images/Svg/dash-svg/recommend-location-icon.svg"
                  alt=""
                />{{ job.location.name }}
              </div>
              <div
                class="recommend-location mx-2 d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-2"
                  src="@/assets/images/Svg/dash-svg/recommend-experience-icon.svg"
                  alt=""
                />{{ job.experience }} years exp.
              </div>
              <div
                class="recommend-location d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-2"
                  src="@/assets/images/Svg/dash-svg/recommend-fulltime-icon.svg"
                  alt=""
                />{{ job.type }}
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <span class="text-ellipsis">{{ job.description }}</span>
          </div>
          <div class="d-flex justify-content-start align-items-center mt-3">
            <h5
              class="home-emp-dash-recommend-heading mb-0 d-flex align-items-center fw-bold primary-text-color"
            >
              PKR {{ job.salary }}/mo
            </h5>
            <div class="recommend-posted-date-time ps-5">
              <span class="d-flex justify-content-center align-items-center">
                <img
                  class="me-1"
                  src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                  alt=""
                />Posted {{ job.posted_at }}
              </span>
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-md-3 me-2" v-for="media in job.job_media">
              <img
                v-if="media.media.type == 'Image'"
                :src="media.media.file_url"
                style="width: 100%; border-radius: 16px"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <p class="text-white">No Jobs Found...</p>
        </div>
      </div>
    </div>
    <ShowJobDetail :item="selectedItem"></ShowJobDetail>
  </div>
</template>
<script>
import ShowJobDetail from "./ShowJobDetail.vue";

export default {
  components: {
    ShowJobDetail,
  },
  data() {
    return {
      activeJobs: [],
      selectedItem: [],
    };
  },
  mounted() {
    this.fetchJobs();
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },
    fetchJobs() {
      this.loading = true;
      const id = this.$route.params.empId;
      this.$store
        .dispatch("jobs/getByEmployer", { id })
        .then((response) => {
          this.activeJobs = response.data.activeJobs;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
