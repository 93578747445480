<template>
  <div class="container login-container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <!-- Sign-up Logo -->
    <div class="login-logo row g-0 justify-content-center">
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <div class="row g-0 text-center mb-5">
      <h3 class="login-h3 mb-0">References</h3>
      <p class="login-p mb-0">You can add upto 3 references</p>
    </div>
    <!-- Sign-up Forms -->
    <form @submit.prevent="submit">
      <template v-for="(reference, index) in references">
        <div class="d-flex justify-content-end" v-if="index > 0">
          <button
            type="button"
            class="btn btn-sm btn-danger"
            @click="removeReference(index)"
          >
            Remove
          </button>
        </div>
        <div class="col-12 form-group mb-4">
          <label class="login-form-label" v-if="translations">{{
            translations.data["full-name"]
          }}</label>
          <input
            type="text"
            class="basic-info-input form-control"
            placeholder="Humza Khan"
            v-model="reference.fName"
          />
        </div>
        <div class="form-group mb-4">
          <label class="login-form-label" v-if="translations">{{
            translations.data["job-category-designation"]
          }}</label>
          <input
            type="text"
            class="login-form-control form-control"
            placeholder="Manager"
            v-model="reference.designation"
          />
        </div>
        <div class="form-group mb-4">
          <label class="login-form-label" v-if="translations">{{
            translations.data["company-name"]
          }}</label>
          <input
            type="text"
            class="login-form-control form-control"
            placeholder="Company Name"
            v-model="reference.companyName"
          />
        </div>
        <div class="form-group mb-4">
          <label class="login-form-label" v-if="translations">{{
            translations.data["email"]
          }}</label>
          <input
            type="email"
            class="login-form-control form-control"
            placeholder="info@gmail.com"
            v-model="reference.email"
          />
        </div>
        <div class="form-group mb-4">
          <label class="login-form-label" v-if="translations">{{
            translations.data["phone-number"]
          }}</label>
          <input
            type="text"
            class="login-form-control form-control"
            placeholder="+92 321 1234567"
            v-model="reference.phone"
            @keydown="numberOnly($event)"
          />
        </div>
        <div class="form-group mb-4">
          <label class="login-form-label" v-if="translations">{{
            translations.data["address"]
          }}</label>
          <input
            type="text"
            class="login-form-control form-control"
            placeholder="32C askari-1 Peshawar, Pakistan"
            v-model="reference.address"
          />
        </div>
        <div class="form-group mb-4">
          <label class="login-form-label" v-if="translations">{{
            translations.data["relation"]
          }}</label>
          <input
            type="text"
            class="login-form-control form-control"
            placeholder="Cousin"
            v-model="reference.relation"
          />
        </div>
      </template>
      <!-- Sign-up Button -->
      <div class="row g-0 mb-5" v-if="references.length < 3">
        <div class="col-12">
          <button
            type="button"
            class="next-ref-button px-2"
            @click="addNewReference"
            v-if="translations"
          >
            {{ translations.data["add-next-reference"] }}
          </button>
        </div>
      </div>
      <div class="row g-0 justify-content-between mb-5">
        <div class="col-8 pe-4">
          <button type="submit" class="basic-info-button">Save</button>
        </div>
        <div class="col-4">
          <router-link
            :to="`/dashboard/${type}`"
            class="d-block basic-info-skip-button text-center"
            style="line-height: 68px"
            >Skip</router-link
          >
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import { isNumber } from "@/helpers/helpers";

export default {
  data() {
    return {
      references: [],
      reference: {
        fName: null,
        companyName: null,
        email: null,
        phone: null,
        relation: null,
        designation: null,
        address: null,
        userId: localStorage.getItem("upId"),
      },
      loading: false,
      type: null,
    };
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  created() {
    const type = localStorage.getItem("type");
    const token = localStorage.getItem("token");
    // if (type && token) {
    //   router.push({ name: "Dashboard", params: { type: type } });
    // }
    this.type = this.$route.params.type;
  },
  mounted() {
    this.addNewReference();
  },
  methods: {
    numberOnly(evt) {
      isNumber(evt);
    },
    addNewReference() {
      if (this.references.length < 3) {
        this.reference.userId = localStorage.getItem("upId");
        this.references.push(Object.assign({}, this.reference));
      }
    },
    removeReference(index) {
      this.references.splice(index, 1);
    },
    submit() {
      this.loading = true;
      const data = {
        references: this.references,
      };
      this.$store
        .dispatch("reference/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("References has been saved successfully.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 10000,
            });
            if (this.type == "worker") {
              router.push({
                path: "/dashboard/worker",
              });
            }
            if (this.type == "employer") {
              router.push({
                path: "/dashboard/employer",
              });
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
