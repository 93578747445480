<template>
  <div class="login-container px-sm-2 px-3 mx-auto">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <!-- Login Logo -->
    <div class="login-logo row g-0 justify-content-center">
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <div class="row g-0 text-center mb-sm-0 mb-5">
      <h3 class="login-h3 mb-0">Verify Email/Phone Number</h3>
      <p class="login-p mb-sm-4 mb-0">Enter your email/phone number.</p>
    </div>
    <!-- Login Forms -->
    <form @submit.prevent="submit">
      <span class="text-success" v-if="msg">{{ msg }}</span>
      <div class="form-group mb-sm-4 mb-3">
        <label class="login-form-label" v-if="translations">
          {{ translations.data["email-password"] }}
          <span class="text-danger">*</span></label
        >
        <span class="text-danger d-block">
          <template v-if="errors.username">
            {{ errors.username[0] }}
          </template>
          <template v-else>
            {{ errors.login }}
          </template>
        </span>
        <input
          type="text"
          class="login-form-control form-control"
          placeholder="info@gmail.com"
          v-model="username"
          required
          autofocus
          autocomplete="off"
        />
      </div>
      <!-- Login Button -->
      <div class="mb-sm-5 mb-4">
        <button type="submit" class="login-button">Submit</button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  data() {
    return {
      loading: false,
      username: null,
      errors: [],
      msg: null,
    };
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  methods: {
    submit() {
      this.loading = true;
      const data = {
        username: this.username,
      };
      this.$store
        .dispatch("users/verifyUsername", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.loading = true;
            const id = response.data.id;
            localStorage.setItem("uid", id);
            this.$store
              .dispatch("users/sendCode", { id })
              .then((response) => {
                this.loading = false;
                if (response.success == true) {
                  router.push(`/verify-code/${btoa(response.data.msg)}`);
                } else {
                  this.msg = response.error.msg;
                }
              })
              .catch((e) => {
                console.log(e);
                this.loading = false;
              });
          } else {
            this.msg = "User not found";
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
