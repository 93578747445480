<template>
  <header>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>

    <div class="container-home-emp-dash mx-auto g-0 px-2">
      <nav
        class="home-emp-nav row g-0 justify-content-between align-items-center"
      >
        <!-- Logo -->
        <div class="col-lg-1 col-sm-2 col-4 header-logo">
          <img
            src="@/assets/images/logo-dark.png"
            alt=""
          />
        </div>
        <div class="col-xl-4 col-5 d-lg-flex d-none">
          <form @submit.prevent="search" v-if="type == 'worker'">
            <input
              type="text"
              class="employer-dash-search-input"
              placeholder="Search for Jobs..."
              v-model="searchValue"
            />
          </form>
          <form @submit.prevent="search" v-if="type != 'worker'">
            <input
              type="text"
              class="employer-dash-search-input"
              placeholder="Search for Workers..."
              v-model="searchValue"
            />
          </form>
          <li
            class="nav-item dropdown ms-3 d-flex justify-content-center align-items-center"
            v-if="type != 'worker'"
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="@/assets/images/filter.png"
                style="margin-top: -6px; width: 14px;"
              />
            </a>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="searchType = 'worker'"
                  >Worker</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="searchType = 'gig'"
                  >Skill-set</a
                >
              </li>
            </ul>
          </li>
        </div>
        <div
          class="col-xl-4 col-lg-5 col-sm-7 col-6 d-flex justify-content-between align-items-center"
        >
          <WorkerNav v-if="type == 'worker'"></WorkerNav>
          <EmployerNav
            v-if="type == 'employer' || type == 'company'"
          ></EmployerNav>
          <!-- Employer & Worker-Selector -->
          <li
            class="nav-item-2 dropdown-2 d-sm-block d-none"
            v-if="type != 'company'"
          >
            <a
              v-if="profiles > 1"
              class="nav-link dropdown-toggle text-center text-capitalize h-100 pt-1"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ type }} <span class="mx-1">|</span>
              <img
                src="@/assets/images/Svg/dash-svg/emp-dash-nav-select-arrow.svg"
                alt=""
              />
            </a>
            <a
              v-else
              class="nav-link text-center text-capitalize h-100 pt-1"
              href="javascript:void(0)"
              aria-expanded="false"
            >
              {{ type }}
            </a>
            <ul class="dropdown-menu" v-if="profiles > 1">
              <li>
                <a
                  v-if="type == 'employer'"
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="switchProfile('worker')"
                  >Worker</a
                >
              </li>
              <li>
                <a
                  v-if="type == 'worker'"
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="switchProfile('employer')"
                  >Employer</a
                >
              </li>
            </ul>
          </li>
          <!-- Notification-Bell-Icon -->
          <div class="dropdown-notification d-flex align-items-center">
            <button
              type="button"
              class="btn position-relative pt-0"
              data-bs-toggle="dropdown"
            >
              <img
                src="@/assets/images/Svg/dash-svg/emp-dash-nav-bell-icon.svg"
                alt=""
              />
              <span
                class="notification fw-bold position-absolute translate-middle badgel"
              >
                {{ notificationCount }}
                <span class="visually-hidden">notifications </span>
              </span>
            </button>
            <ul class="dropdown-menu dropdown-menu-notification text-light">
              <li>
                <div class="row g-0 pt-2 px-3 fs-6 fw-bold">Notifications</div>
                <ul class="nav nav-underline px-3 pb-1">
                  <li class="nav-item">
                    <a
                      v-if="notificationCount > 0"
                      class="nav-link text-light pb-0"
                      href="javascript:void(0)"
                      @click="readAll"
                      >Mark all as read</a
                    >
                  </li>
                </ul>
                <div class="notifi-background">
                  <template v-for="notification in notifications">
                    <div
                      class="row py-2 align-items-center d-flex flex-nowrap px-3"
                    >
                      <!-- <div class="col-3">
                      <img
                        src="@/assets/images/Svg/dash-svg/recent-work-pic-1.svg"
                        alt=""
                      />
                    </div> -->
                      <div class="col-11">
                        <span class="fw-bold" v-if="notification.body.title">{{
                          notification.body.title
                        }}</span>
                        <span class="fw-bold" v-else>{{
                          JSON.parse(notification.body).title
                        }}</span>
                        <div>
                          <span class="fw-bold" v-if="notification.body.body">{{
                            notification.body.body
                          }}</span>
                          <span class="fw-bold" v-else>{{
                            JSON.parse(notification.body).body
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="dot g-0"
                        v-if="notification.is_read == 0"
                      ></div>
                      <div
                        class="dot g-0"
                        v-else-if="notification.body.is_read == 0"
                      ></div>
                    </div>
                    <hr class="my-0" />
                  </template>
                </div>
              </li>
            </ul>
          </div>
          <!-- Setting & Logout toggle -->
          <li class="nav-item-3 dropdown-3">
            <a
              class="nav-link dropdown-toggle"
              href="javascript:void(0)"
              role="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              <img
                class="position-relative"
                src="@/assets/images/Svg/dash-svg/home-emp-dash-nav-toogle-icon.svg"
                alt=""
              />
            </a>
            <ul class="dropdown-menu">
              <li class="" @click="showDropclick">
                <a
                  class="dropdown-item text-start d-sm-none d-flex align-items-center justify-content-between worker-selector-mobile"
                >
                  <span class="">Worker</span>
                  <img
                    src="@/assets/images/Svg/dash-svg/emp-dash-nav-select-arrow.svg"
                    alt=""
                  />
                </a>
              </li>
              <div
                :class="{
                  'worker-nested-dropdown': showNesteddrop,
                  'd-none': !showNesteddrop,
                }"
              >
                <li>
                  <a class="dropdown-item ps-5 py-1" href="#">Worker</a>
                </li>
                <li>
                  <a class="dropdown-item ps-5 py-1" href="#">Employer</a>
                </li>
              </div>
              <div class="d-lg-none d-block">
                <li>
                  <router-link
                    to="/profile-mobile"
                    class="dropdown-item text-start"
                    href="#"
                    >Profile</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/recent-work-mobile"
                    class="dropdown-item text-start"
                    href="#"
                    >Recently Worked With</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/message-mobile"
                    class="dropdown-item text-start"
                    href="#"
                    >Message</router-link
                  >
                </li>
              </div>
              <button
                type="button"
                class="btn p-0 w-100"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal-setting"
              >
                <li>
                  <a class="dropdown-item text-start" href="javascript:void(0)"
                    >Settings</a
                  >
                </li>
              </button>
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="logout"
                  >Logout <span class="mx-2">|</span>
                  <img
                    src="@/assets/images/Svg/dash-svg/home-emp-dash-nav-logout-icon.svg"
                    alt=""
                  />
                </a>
              </li>
            </ul>
          </li>
        </div>
      </nav>
    </div>

    <Searchmobile class="d-lg-none d-block"></Searchmobile>
    <Settings></Settings>
    <ChangePassword></ChangePassword>
  </header>
</template>

<script>
import Settings from "./modals/Settings.vue";
import ChangePassword from "./modals/ChangePassword.vue";
import WorkerNav from "./nav/worker.vue";
import EmployerNav from "./nav/employer.vue";
import router from "@/router";
import Searchmobile from "./searchJobsmobile.vue";

export default {
  components: {
    Settings,
    ChangePassword,
    WorkerNav,
    EmployerNav,
    Searchmobile,
  },
  props: {
    profiles: 0,
  },
  data() {
    return {
      loading: false,
      notificationCount: 0,
      showNesteddrop: false,
      searchValue: null,
      user: {
        worker: [],
      },
      loading: false,
      references: [],
      reference: {
        f_name: null,
        l_name: null,
        phone: null,
        relation: null,
        designation: null,
        userProfileId: null,
      },
      picturesPreview: [],
      audioPreview: null,
      audioFile: null,
      isRecommendation: true,
      isPostedJobs: false,
      postedJobs: [],
      jobPost: [],
      application: {
        workerId: null,
        jobId: null,
        status: "Applied",
        description: null,
      },
      type: null,
      notifications: [],
      uid: null,
      searchType: "worker",
      channelName: null,
      pusherChannel: null,
    };
  },
  inject: ["pusherService"],
  created() {
    this.type = localStorage.getItem("type");
    if (this.$route.params.search) {
      this.searchValue = atob(this.$route.params.search);
    }
    this.uid = localStorage.getItem("upId");
    this.channelName = "user." + this.uid;
  },
  mounted() {
    this.pusher();
    this.fetchNotifications();
  },
  onUnmounted() {
    this.pusherService.unsubscribe(this.channelName);
  },
  methods: {
    switchProfile(type) {
      localStorage.setItem("type", type);
      if (type == "employer") {
        window.location.href = "/dashboard/employer";
      }
      if (type == "worker") {
        window.location.href = "/dashboard/worker";
      }
    },
    readAll() {
      this.loading = true;
      const data = this.notifications;
      this.$store
        .dispatch("notification/readAll", { data })
        .then((response) => {
          this.fetchNotifications();
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchNotifications() {
      const id = this.uid;
      this.$store
        .dispatch("notification/getAll", { id })
        .then((response) => {
          this.notifications = response.data.notifications;
          this.notificationCount = response.data.count;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    pusher() {
      this.pusherChannel = this.pusherService.subscribe(this.channelName);
      this.pusherChannel.bind(this.channelName, (data) => {
        this.notifications.unshift(data);
        this.notificationCount += 1;
      });
    },
    showDropclick() {
      if (!this.showNesteddrop) {
        this.showNesteddrop = true;
      } else {
        this.showNesteddrop = false;
      }
    },
    search() {
      window.location.href = `/search/${btoa(this.searchValue)}`;
    },
    logout() {
      this.loading = true;
      this.$store
        .dispatch("users/logout")
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            localStorage.clear();
            router.push({ name: "RoleSelection" });
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    showHome() {
      this.isRecommendation = true;
      this.isPostedJobs = false;
    },
  },
};
</script>
