import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    get({ dispatch, commit }, { key }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/setting/${key}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
