<template>
  <div class="container login-container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <!-- Sign-up Logo -->
    <div class="login-logo row g-0 justify-content-center">
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <div class="row g-0 text-center">
      <h3 class="login-h3 mb-0">Join us</h3>
      <p class="login-p mb-4">EMP X connects online worker with customer</p>
    </div>
    <!-- Sign-up Forms -->
    <form @submit.prevent="submit">
      <div class="form-group mb-4">
        <label for="InputEmail1" class="login-form-label" v-if="translations"
          >{{ translations.data["cnic"] }}
          <span class="text-danger">*</span></label
        >
        <span class="text-danger d-block">
          <template v-if="errors.cnic">
            {{ errors.cnic[0] }}
          </template>
          <template v-else>
            {{ errors.user }}
          </template>
        </span>
        <input
          type="text"
          class="login-form-control form-control"
          id="exampleInputEmail1"
          placeholder="3520112345678"
          v-model="user.cnic"
          minlength="13"
          maxlength="13"
          required
          autofocus
          autocomplete="off"
          @keydown="numberOnly($event)"
        />
      </div>
      <div class="form-group mb-4" v-if="isVerified">
        <label for="InputPassword1" class="login-form-label" v-if="translations"
          >{{ translations.data["password"] }}
          <span class="text-danger">*</span></label
        >
        <span class="text-danger d-block">
          <template v-if="errors.password">
            {{ errors.password[0] }}
          </template>
        </span>
        <input
          type="password"
          class="login-form-control form-control"
          id="exampleInputPassword1"
          placeholder="Password"
          v-model="user.password"
          required
          autocapitalize="off"
        />
      </div>
      <div class="form-group mb-sm-5 mb-4" v-if="isVerified">
        <label for="InputPassword1" class="login-form-label" v-if="translations"
          >{{ translations.data["re-enter-password"] }}
          <span class="text-danger">*</span></label
        >
        <span class="text-danger d-block">
          {{ errors.confirmPassword }}
        </span>
        <input
          type="password"
          class="login-form-control form-control"
          id="exampleInputPassword1"
          placeholder="Password"
          v-model="user.confirmPassword"
          autocomplete="off"
          required
        />
      </div>
      <div class="row g-0 justify-content-between mb-4" v-if="isVerified">
        <div class="col-md-6">
          <label class="login-form-check">
            <span class="login-check-span position-relative"
              ><span class="text-danger">*</span> I agree to the
              <span class="text-decoration-underline fw-bold"
                >terms of service</span
              >
            </span>
            <input
              class="login-form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              required
            />
            <span class="login-checkmark"></span>
          </label>
        </div>
      </div>
      <!-- Sign-up Button -->
      <div class="row g-0 mb-sm-5 mb-4">
        <button type="submit" class="login-button" v-if="isVerified">
          Register
        </button>
      </div>
      <div class="row g-0 mb-5">
        <button type="submit" class="login-button" v-if="!isVerified">
          Continue
        </button>
      </div>
    </form>
    <!-- OR & Lines -->
    <div class="row g-0 align-items-center">
      <div class="login-line col-5"></div>
      <div class="col-2 g-0 d-flex justify-content-center">
        <span
          class="login-OR my-0 d-flex justify-content-center align-items-center"
          >Or</span
        >
      </div>
      <div class="login-line-2 col-5 g-0"></div>
    </div>
    <!-- Sign-up Footer -->
    <div class="row text-center my-5">
      <h4 class="login-h4">
        Already on <span class="fw-bold">Durshala</span>
        <span
          class="help-icon login-help-icon text-center d-inline-block ms-1 me-1"
          >?</span
        >
        <router-link
          :to="`/login/${role}`"
          class="text-decoration-underline fw-bold register-link"
          >Sign in</router-link
        >
      </h4>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import router from "@/router";
import { mapGetters } from "vuex";
import { isNumber } from "@/helpers/helpers";

export default {
  data() {
    return {
      loading: false,
      role: null,
      user: {
        cnic: null,
        password: null,
        confirmPassword: null,
        is_worker: 0,
        is_employer: 0,
      },
      errors: [],
      isVerified: false,
    };
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  created() {
    const type = localStorage.getItem("type");
    const token = localStorage.getItem("token");
    // if (type && token) {
    //   router.push({ name: "Dashboard", params: { type: type } });
    // }
  },
  mounted() {
    this.role = this.$route.params.type;
  },
  methods: {
    numberOnly(evt) {
      isNumber(evt);
    },
    submit() {
      this.loading = true;
      this.errors = [];
      if (this.role == "worker") {
        this.user.is_worker = 1;
      }
      if (this.role == "employer") {
        this.user.is_employer = 1;
      }
      const data = toRaw(this.user);
      if (!this.isVerified) {
        this.$store
          .dispatch("users/verifyUsername", { data })
          .then((response) => {
            if (response.success == false) {
              this.isVerified = true;
            } else if (response.success == true) {
              if (this.role == "worker" && response.data.is_worker == 1) {
                this.errors.user = "This cnic is already registered as Worker";
              }
              if (this.role == "employer" && response.data.is_employer == 1) {
                this.errors.user =
                  "This cnic is already registered as Employer";
              }
              if (
                this.role == "company" &&
                (response.data.is_worker == 1 || response.data.is_employer == 1)
              ) {
                this.errors.user = "This cnic is already registered";
              }
              if (this.role == "worker" && response.data.is_employer == 1) {
                this.$store
                  .dispatch("users/updateUser", { data })
                  .then((response) => {
                    if (response.success == true) {
                      router.push({
                        name: "Login",
                        params: {
                          type: this.role,
                          msg: btoa(
                            "You are registered as Worker. Please Login with your Employer Username & Password to setup your Worker Profile."
                          ),
                        },
                      });
                    }
                    this.loading = false;
                  })
                  .catch((e) => {
                    console.log(e);
                    this.loading = false;
                  });
              }
              if (this.role == "employer" && response.data.is_worker == 1) {
                this.$store
                  .dispatch("users/updateUser", { data })
                  .then((response) => {
                    if (response.success == true) {
                      router.push({
                        name: "Login",
                        params: {
                          type: this.role,
                          msg: btoa(
                            "You are registered as Employer. Please Login with your Worker Username & Password to setup your Employer Profile."
                          ),
                        },
                      });
                    }
                    this.loading = false;
                  })
                  .catch((e) => {
                    console.log(e);
                    this.loading = false;
                  });
              }
            }
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      } else {
        if (this.user.password !== this.user.confirmPassword) {
          this.errors.confirmPassword = "Password didn't match";
          this.loading = false;
        } else {
          this.$store
            .dispatch("users/register", { data })
            .then((response) => {
              if (response.success == true) {
                router.push({
                  name: "Login",
                  params: {
                    type: this.role,
                    msg: btoa("You are successfully registered"),
                  },
                });
              } else {
                this.errors = response.error;
              }
              this.loading = false;
            })
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
        }
      }
    },
  },
};
</script>
