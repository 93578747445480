<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>

    <div>
      <div class="d-flex align-items-center home-emp-dash-create-job">
        <div class="ms-xl-5 ms-lg-4 ms-sm-3 ms-2">
          <div class="dash-create-job-pic text-center position-relative">
            <img v-if="profilePic" :src="profilePic" alt="" />
            <img
              v-else
              src="@/assets/images/Svg/dash-svg/dash-profile-pic.svg"
              alt=""
            />
          </div>
        </div>
        <button
          class="btn w-100"
          data-bs-toggle="modal"
          data-bs-target="#createGigModal"
        >
          <input
            type="text"
            class="search-create-job w-100"
            placeholder="Create a Skill-set"
          />
        </button>
      </div>
    </div>

    <div id="recommendations">
      <div class="row w-75">
        <h5 class="home-emp-dash-recommend-heading fw-normal mt-4 mb-sm-1 mb-0">
          Skill-set
        </h5>
      </div>
      <template v-for="gig in gigs">
        <div
          class="row g-0 home-emp-dash-recommends mt-4"
          data-bs-toggle="modal"
          data-bs-target="#showGigDetailModal"
          @click="selectGig(gig)"
        >
          <div class="container p-sm-4 p-3">
            <div class="row gigs-card g-0">
              <div class="col-sm-6 mb-sm-2 mb-2">
                <div class="row g-0 justify-content-between ms-lg-3 mt-3">
                  <div class="col-3">
                    <h6>{{ gig.title }}</h6>
                  </div>
                </div>
                <div class="row g-0 ms-lg-3 mt-2 pe-sm-5">
                  <p class="text-ellipsis">
                    {{ gig.details }}
                  </p>
                </div>
                <span class="skill-tag" v-if="gig.skill">{{
                  gig.skill.name
                }}</span>
                <div class="row g-0 mt-4 ms-lg-3">
                  <h5
                    class="home-emp-dash-recommend-heading fw-bold col-md-6 mb-md-0 mb-2"
                  >
                    {{ gig.rate }} PKR
                  </h5>
                  <span
                    class="d-flex justify-content-start align-items-center col-md-6"
                  >
                    <img
                      class="me-1"
                      src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                      alt=""
                    />Posted
                    {{ gig.posted_at }}
                  </span>
                </div>
                <!-- <div class="recommend-posted-date-time ms-3 mt-5 pt-3"></div> -->
              </div>
              <div class="col-sm-6 text-center d-flex align-items-center">
                <div class="gig-img-border-radius">
                  <img
                    v-if="gig.cover_image"
                    :src="gig.cover_image.media.file_url"
                    alt=""
                    style="width: 100%"
                  />
                  <img
                    v-else
                    src="@/assets/images/Svg/dash-svg/recommend-carpenter-pic.svg"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <CreateGig @saved="fetchGigs"></CreateGig>
    <ShowGigDetail :item="selectedGig"></ShowGigDetail>
  </div>
</template>
<script>
import CreateGig from "./CreateGig.vue";
import ShowGigDetail from "./ShowGigDetail.vue";

export default {
  components: {
    CreateGig,
    ShowGigDetail,
  },
  data() {
    return {
      gigs: null,
      loading: false,
      selectedGig: [],
      coverImage: null,
      profilePic: null,
    };
  },
  mounted() {
    if (localStorage.getItem("profilePic")) {
      this.profilePic = localStorage.getItem("profilePic");
    }
    this.fetchGigs();
  },
  methods: {
    selectGig(gig) {
      this.selectedGig = gig;
    },
    fetchGigs() {
      this.loading = true;
      const id = localStorage.getItem("upId");
      this.$store
        .dispatch("gig/getAll", { id })
        .then((response) => {
          this.gigs = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
