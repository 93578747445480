<template>
  <div>
    <h5>Privacy Policy</h5>
    <div class="p-sm-5 p-3 custom-card mt-3">
      <p v-html="policy"></p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      policy: null,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      const key = "policy";
      this.$store
        .dispatch("setting/get", { key })
        .then((response) => {
          this.policy = response.data.value;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
