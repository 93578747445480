<template>
  <div class="custom-select" :class="{ open: isOpen }">
    <div class="select-box" @click="openDropDown">
      <input type="text" class="tags_input" name="tags" hidden />
      <div class="selected-options">
        <template v-for="option in selectedOptions">
          <span class="tag" v-if="option != null"
            >{{ option[label]
            }}<span class="remove-tag" @click="removeOption(option)"
              >&times;</span
            ></span
          >
        </template>
      </div>
      <div class="arrow">
        <i class="fa fa-angle-down"></i>
      </div>
    </div>
    <div class="options">
      <div
        class="option-search-tags"
        v-if="selectedOptions.length < maxOptions"
      >
        <input
          type="text"
          placeholder="Search"
          class="search-tags"
          v-model="searchValue"
        />
      </div>
      <div
        class="option"
        v-if="selectedOptions.length < maxOptions"
        v-for="option in filteredItems"
        @click="selectOption(option)"
      >
        {{ option[label] }}
      </div>
      <div class="option" v-else>You can add max {{ maxOptions }} options.</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    maxOptions: {
      type: Number,
      default: 3,
    },
    options: {
      type: Array,
      default: [],
    },
    label: {
      type: String,
      default: "name",
    },
    selectOptions: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      isOpen: false,
      selectedOptions: [],
      searchValue: "",
    };
  },
  computed: {
    filteredItems() {
      return this.options.filter((item) => {
        return (
          item[this.label]
            .toLowerCase()
            .indexOf(this.searchValue.toLowerCase()) > -1
        );
      });
    },
  },
  mounted() {
    if (this.selectOptions.length > 0) {
      this.selectedOptions = this.selectOptions;
    }
  },
  methods: {
    selectOption(option) {
      if (this.selectedOptions.length < this.maxOptions) {
        this.selectedOptions.push(option);
        this.$emit("items", this.selectedOptions);
      }
    },
    removeOption(option) {
      this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1);
      this.$emit("items", this.selectedOptions);
    },
    openDropDown() {
      if (this.isOpen) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
    },
  },
};
</script>
<style scoped>
.custom-select {
  position: relative;
  width: 100%;
}
.select-box {
  height: 70px;
  border: 1px solid #009881;
  border-radius: 68px;
  color: #fff;
  padding-left: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selected-options {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
}
.tag {
  background-color: #d5d5d5a3;
  color: #222;
  border-radius: 30px;
  margin-right: 15px;
  padding: 6px 14px;
  display: flex;
  align-items: center;
}
.remove-tag {
  margin-left: 6px;
  cursor: pointer;
}
.options {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #009881;
  border-top: none;
  max-height: 225px;
  overflow-y: auto;
  z-index: 1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.open .options {
  display: block;
}
.option-search-tags {
  background-color: #fff;
  border: 1px solid #009881;
  padding: 8px 0;
  margin: 8px;
}
.search-tags {
  width: 100%;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 14px;
  background-color: transparent;
  color: #222;
}
.search-tags::placeholder {
  color: #222;
}
.option {
  padding: 12px;
  cursor: pointer;
  color: #222;
}
.option.active {
  color: #222;
  background-color: #f2f2f2;
  border-bottom: 1px solid #eaeaea;
}
.tag:hover,
.option:hover {
  background-color: #eaeaea;
  color: #222;
}
.arrow {
  margin: 0 30px;
  cursor: pointer;
}
.fa-angle-down {
  color: #009881;
  font-size: 20px;
}
</style>
